import React from "react";
import "./window-page.scss";
import cn from "classnames";

export type WindowPageProps = {
  children: React.ReactNode;
  size?: Size;
  padding?: Padding;
};

export type Size = "base" | "sm";
export type Padding = "base" | "lg";

export const WindowPage: React.FC<WindowPageProps> = ({
  children,
  size = "base",
  padding = "base",
}) => (
  <div
    className={cn(
      "window-page",
      size ? `window-page--size-${size}` : "",
      padding ? `window-page--padding-${padding}` : ""
    )}
  >
    {children}
  </div>
);
