import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useAppSelector } from "../../app/hooks";
import useNetworkChanger from "../../hooks/useNetworkChanger";
import { useDispatch } from "react-redux";
import useWeb3Auth from "../../app/useWeb3Auth";
import { ConnectorNames } from "../../app/types";
import { getContract2, getSigner, signMessage } from "../../app/web3react";
import AbiID from "../../abi/id.json";
import AuthService from "../../services/AuthService";
import { setAddress, setPassports, setToken } from "../../app/UserSlice";
import { Container } from "../../components/Grid/Container";
import { Row } from "../../components/Grid/Row";
import { Column } from "../../components/Grid/Column";
import { WindowPage } from "../../components/WindowPage";
import { WalletInfo, WalletInfoLogin } from "../../components/WalletInfo";
import ImgMetamask from "../../assets/images/img-wallet-metamask.png";
import { BodySmall, BodyXSmall } from "../../components/UI/Text";
import { ModalCreatePassport } from "../../components/Modal/ModalCreatePassport";
import { SelectCustom } from "../../components/UI/SelectCustom";
import { Input } from "../../components/UI/Input";
import { Tooltips } from "../../components/UI/Tooltips";
import { IconTooltipInfo } from "../../components/UI/Icon";
import { ModalConfirm } from "../../components/Modal/ModalConfirm";
import { ModalTransaction } from "../../components/Modal/ModalTransaction";
import { ModalSuccess } from "../../components/Modal/ModalSuccess";
import { ModalError } from "../../components/Modal/ModalError";
import { ModalSwitchChain } from "../../components/Modal/ModalSwitchChain";
import { useSearchParams } from "react-router-dom";
import { IBlockchain } from "../../models/IBlockchain";
import { IVerifiersByChainId } from "../../models/iVerifiers";
import { ChainIcon } from "../../components/ChainIcon";
import { verifierProviders } from "../../app/verifiers";

interface ICreatePassportProvider {
  children: any;
  isCheckup?: boolean;
}

interface IOption {
  label: string;
  value: number;
}

export const CreatePassportProvider: React.FC<ICreatePassportProvider> = ({
  children,
  isCheckup = false,
}) => {
  const { active, account, library, chainId } = useWeb3React();
  const passport = useAppSelector((state) => state.user);
  const currentBlockchain = useAppSelector(
    (state) => state.config.currentBlockchain
  );

  // Variables for checkupRoute
  const [searchParams] = useSearchParams();
  const loginMessageFromParams = searchParams.get("loginMessage");
  const redirectUrlFromParams = searchParams.get("redirectUrl");

  // Variables for chains
  const blockchains = useAppSelector((state) => state.config.blockchains);
  const verifiersByChain: IVerifiersByChainId = {};
  blockchains.map((chain: IBlockchain) => {
    verifiersByChain[chain.chainId] = chain.verifiers;
  });

  const [verifiersOptions, setVerifiersOptions] = useState<IOption[]>([]);
  const [blockchainOptions, setBlockchainOptions] = useState<IOption[]>([]);
  const [verifierFee, setVerifierFee] = useState<null | number>(null);
  const [selectedChainId, setSelectedChainId] = useState<null | number>(null);
  const [selectedVerifierId, setSelectedVerifierId] = useState<null | number>(
    null
  );
  const { getChainById } = useNetworkChanger();

  // Variables for modal and state logic
  const [nickname, setNickname] = useState("");
  const [isOpenCreatePassportModal, setIsOpenCreatePassportModal] =
    useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenTransactionModal, setIsOpenTransactionModal] = useState(false);
  const [isOpenMintSuccessModal, setIsOpenMintSuccessModal] = useState(false);
  const [isOpenMintErrorModal, setIsOpenMintErrorModal] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { login } = useWeb3Auth();
  const connectWallet = (connectorId: ConnectorNames) => {
    login(connectorId);
  };
  const onChangeNickname = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setNickname(e.currentTarget.value);
  };
  const onCreate = () => setIsOpenCreatePassportModal(true);
  const onCloseCreatePassportModal = () => setIsOpenCreatePassportModal(false);
  const onCloseMintSuccessModal = () => setIsOpenMintSuccessModal(false);
  const onCloseMintErrorModal = () => setIsOpenMintErrorModal(false);

  useEffect(() => {
    setBlockchainOptions(
      blockchains.map((i) => {
        return {
          label: i.name,
          value: i.chainId,
        };
      })
    );
  }, [blockchains]);

  useEffect(() => {
    if (selectedChainId && verifiersByChain[selectedChainId]) {
      const verifiers = verifiersByChain[selectedChainId];
      setVerifiersOptions(
        verifiers.map((i) => {
          const provider =
            verifierProviders[i.provider] && verifierProviders[i.provider].name
              ? verifierProviders[i.provider].name
              : i.provider;
          return {
            label: provider,
            value: i.id,
          };
        })
      );
    }
  }, [selectedChainId]);

  useEffect(() => {
    if (
      isCheckup &&
      loginMessageFromParams &&
      redirectUrlFromParams &&
      account &&
      passport.address.toUpperCase() !== account?.toUpperCase()
    ) {
      onLogin();
    }
  }, [account]);

  useEffect(() => {
    if (chainId && blockchainOptions.find((i) => i.value === chainId)) {
      setSelectedChainId(chainId);
    }
  }, [chainId]);

  useEffect(() => {
    if (
      !active ||
      !account ||
      !currentBlockchain ||
      selectedVerifierId === null ||
      selectedChainId !== chainId
    ) {
      setVerifierFee(null);
      return;
    }

    const contract = getContract2(
      currentBlockchain.contractAddress,
      AbiID,
      library,
      account
    );

    contract.verifiers(selectedVerifierId).then((value: any) => {
      const fee = +value.mintFee.toString() / Math.pow(10, 18);
      setVerifierFee(fee);
    });
  }, [selectedVerifierId]);

  const onMint = async () => {
    if (
      !active ||
      !account ||
      !currentBlockchain ||
      selectedChainId !== chainId
    )
      return;

    setIsOpenCreatePassportModal(false);
    setIsOpenConfirmModal(true);
    const contract = getContract2(
      currentBlockchain.contractAddress,
      AbiID,
      library,
      account
    );

    const value = await contract.verifiers(selectedVerifierId);

    const fee = +value.mintFee.toString() / Math.pow(10, 18);
    console.log(fee);

    const data = contract.interface.encodeFunctionData(
      contract.interface.getFunction("mintPassport"),
      [selectedVerifierId, nickname]
    );

    const tx = {
      from: account,
      to: currentBlockchain.contractAddress,
      // value: toPowAndHex(value / 10 ** 18),
      value: value.mintFee,
      data,
      chainId: chainId,
    };
    getSigner(library, account)
      .sendTransaction(tx)
      .then((result: any) => {
        setIsOpenConfirmModal(false);
        setIsOpenTransactionModal(true);
        return result.wait();
      })
      .then((result: any) => {
        console.info(result);
        setIsOpenTransactionModal(false);
        setIsOpenMintSuccessModal(true);
      })
      .catch((error: any) => {
        console.error("console.error", error);
        setIsOpenConfirmModal(false);
        setIsOpenTransactionModal(false);
        const message = error.data.message;
        if (message) {
          const pos1 = message.indexOf("message");
          const pos2 = message.substring(pos1).indexOf('"}');
          if (pos1 === -1 || pos2 === -1) {
            setError(message);
          } else {
            setError(message.substring(pos1 + 10, pos1 + pos2));
          }
        } else {
          setError("Unknown error");
        }
        setIsOpenMintErrorModal(true);
      });
  };
  const onLogin = () => {
    if (isCheckup) {
      if (loginMessageFromParams && redirectUrlFromParams && account) {
        return signMessage(library, account, loginMessageFromParams).then(
          (signature: any) => {
            localStorage.setItem("checkoutSignature", signature);
            AuthService.serviceLogin(signature, loginMessageFromParams).then(
              (response) => {
                localStorage.setItem("checkoutToken", response.data.token);
                dispatch(setPassports(response.data.user.passports));
                dispatch(setAddress(response.data.user.address));
              }
            );
          }
        );
      }
    }
    if (!active || !account) return;
    AuthService.getLoginMessage(account).then(async (response) => {
      signMessage(library, account, response.data.loginMessage).then(
        (signature: any) => {
          AuthService.login(account, signature).then((response) => {
            localStorage.setItem("token", response.data.token);
            dispatch(setPassports(response.data.user.passports));
            dispatch(setAddress(response.data.user.address));
            dispatch(setToken(response.data.token));
          });
        }
      );
    });
  };

  if (!active || !account) {
    return (
      <div className="passports">
        <Container>
          <Row>
            <Column col={12}>
              <WindowPage>
                <WalletInfo
                  img={ImgMetamask}
                  imgALt="Metamask"
                  info="Connect your wallet to use Hashbon Pass"
                  textButton="Connect Wallet"
                  onClick={() => connectWallet(ConnectorNames.Injected)}
                />
              </WindowPage>
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
  if (
    active &&
    account &&
    // passport.address.length > 0 &&
    passport.address.toUpperCase() !== account?.toUpperCase()
  ) {
    return (
      <div className="passports">
        <Container>
          <Row>
            <Column col={12}>
              <WindowPage>
                <WalletInfoLogin
                  info="Click the login button and sign the message in your wallet"
                  textButton="Log in"
                  onClick={onLogin}
                />
              </WindowPage>
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
  return (
    <div className="passports">
      {children && React.isValidElement(children) ? (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        React.cloneElement(children, { onCreate })
      ) : (
        <></>
      )}

      <ModalCreatePassport
        onClose={onCloseCreatePassportModal}
        isOpen={isOpenCreatePassportModal}
        title="Create an NFT Passport"
        desc="Please enter your nickname, then click “Create”"
        onClickButton={onMint}
        buttonText="Create"
        disabledButton={selectedChainId === null || selectedChainId !== chainId}
        descNetwork={
          <>
            {selectedChainId && selectedChainId !== chainId && (
              <BodySmall color="error">
                Please switch to {getChainById(selectedChainId).name} chain
                proceeding to the next step.
              </BodySmall>
            )}
          </>
        }
      >
        <SelectCustom
          options={blockchainOptions}
          placeholder={"Select chain..."}
          defaultValue={blockchainOptions.find(
            (i) => i.value === selectedChainId
          )}
          onChange={(e: any) => {
            setSelectedChainId(e.value);
          }}
        />

        <SelectCustom
          options={verifiersOptions}
          placeholder={"Select verifier..."}
          defaultValue={verifiersOptions.find(
            (i) => i.value === selectedVerifierId
          )}
          onChange={(e: any) => {
            setSelectedVerifierId(e.value);
          }}
        />

        <Input
          name="test"
          type="text"
          label="Nickname"
          placeholder="Enter Your Nickname"
          value={nickname}
          onChange={onChangeNickname}
          labelIcon={
            <Tooltips
              content={
                <BodyXSmall>
                  Nickname must be 4 - 12 characters long
                  <br />
                  Must not start with special characters
                  <br />
                  Must not start or end with a space
                  <br />
                  Must not contain multiple spaces in a row
                </BodyXSmall>
              }
              placement="right"
              size="base"
            >
              <IconTooltipInfo />
            </Tooltips>
          }
        />
        {verifierFee !== null && selectedChainId && (
          <div className="modal-create-passport__fee">
            <div className="modal-create-passport__fee--key">
              <BodySmall color="three">Operation cost:</BodySmall>
            </div>
            <div className="modal-create-passport__fee--value">
              <BodySmall>{verifierFee}</BodySmall>
              <ChainIcon chainId={selectedChainId} />
            </div>
          </div>
        )}
      </ModalCreatePassport>

      <ModalConfirm isOpen={isOpenConfirmModal} />

      <ModalTransaction isOpen={isOpenTransactionModal} />

      <ModalSuccess
        isOpen={isOpenMintSuccessModal}
        onClose={onCloseMintSuccessModal}
        title="Transaction successful!"
        desc="Your passport has been created. When the sync is done, your passport will appear on the list."
        buttonText="Done!"
      />

      <ModalError
        isOpen={isOpenMintErrorModal}
        onClose={onCloseMintErrorModal}
        title="Error !"
        text={error}
      />

      <ModalSwitchChain
        isOpen={false}
        onClose={() => {}}
        title="Please switch chain"
        text="Please switch to BSC Testnet chain before proceeding to the next step."
      />
    </div>
  );
};
