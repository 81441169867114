import React from "react";
import "./icon.scss";

export type IconProps = {
  icons?: string;
  name?: string;
  size?: Size;
  stroke?: Stroke;
  fill?: Fill;
};

type Stroke = "none" | "white" | "primary";
type Fill = "white" | "none" | "primary";
export type Size =
  | "base"
  | "sm"
  | "2sm"
  | "2sm"
  | "3sm"
  | "md"
  | "4md"
  | "lg"
  | "xl";

export const Icon: React.FC<IconProps> = ({
  icons,
  name,
  size = "base",
  stroke,
  fill,
}) => (
  <svg
    className={`icon ${name} 
			${size ? `icon--size-${size}` : ""}
			${stroke ? `icon--stroke-${stroke}` : ""}
			${fill ? `icon--fill-${fill}` : ""}
		`}
  >
    <use xlinkHref={`${icons}#${name}`} />
  </svg>
);
