import React from "react";
import { IconImg, Size } from "./IconImg";
import IconImgEditBase from "../../../assets/icon/icon--edit.svg";

import IconCoingekoHashBase from "../../../assets/icon/icon--coingeko-hash.svg";
import IconMarketHashBase from "../../../assets/icon/icon--market-hash.svg";
import IconMediumHashBase from "../../../assets/icon/icon--medium-hash.svg";
import IconRoboHashBase from "../../../assets/icon/icon--robo-hash.svg";
import IconTelegramHashBase from "../../../assets/icon/icon--telegram-hash.svg";
import IconTwitterHashBase from "../../../assets/icon/icon--twitter-hash.svg";
import IconBscBase from "../../../assets/icon/icon--bsc.svg";
import IconSumSubBase from "../../../assets/icon/icon--sumsub.svg";
import IconPolygonBase from "../../../assets/icon/icon--polygon.svg";
import IconAuroraBase from "../../../assets/icon/icon--logo-aurora.svg";
import IconEthereumBase from "../../../assets/icon/icon--logo-ethereum.svg";
import IconOKXChainBase from "../../../assets/icon/icon--logo-okx-chain.svg";

type IconImgUIProps = {
  size?: Size;
};
export const IconPolygon: React.FC<IconImgUIProps> = ({ size }) => (
  <IconImg icons={IconPolygonBase} name="icon--polygon" size={size} />
);
export const IconEthereum: React.FC<IconImgUIProps> = ({ size }) => (
  <IconImg icons={IconEthereumBase} name="icon--logo-ethereum" size={size} />
);
export const IconOKXChain: React.FC<IconImgUIProps> = ({ size }) => (
  <IconImg icons={IconOKXChainBase} name="icon--logo-okx-chain" size={size} />
);
export const IconAurora: React.FC<IconImgUIProps> = ({ size }) => (
  <IconImg icons={IconAuroraBase} name="icon--logo-aurora" size={size} />
);
export const IconBsc: React.FC<IconImgUIProps> = ({ size }) => (
  <IconImg icons={IconBscBase} name="icon--bsc" size={size} />
);

export const IconSumSub: React.FC<IconImgUIProps> = ({ size }) => (
  <IconImg icons={IconSumSubBase} name="icon--sumsub" size={size} />
);
export const IconMediumHash: React.FC<IconImgUIProps> = ({ size }) => (
  <IconImg icons={IconMediumHashBase} name="icon--medium-hash" size={size} />
);
export const IconMarketHash: React.FC<IconImgUIProps> = ({ size }) => (
  <IconImg icons={IconMarketHashBase} name="icon--market-hash" size={size} />
);
export const IconCoingekoHash: React.FC<IconImgUIProps> = ({ size }) => (
  <IconImg
    icons={IconCoingekoHashBase}
    name="icon--coingeko-hash"
    size={size}
  />
);
export const IconRoboHash: React.FC<IconImgUIProps> = ({ size }) => (
  <IconImg icons={IconRoboHashBase} name="icon--robo-hash" size={size} />
);
export const IconTelegramHash: React.FC<IconImgUIProps> = ({ size }) => (
  <IconImg
    icons={IconTelegramHashBase}
    name="icon--telegram-hash"
    size={size}
  />
);
export const IconTwitterHash: React.FC<IconImgUIProps> = ({ size }) => (
  <IconImg icons={IconTwitterHashBase} name="icon--twitter-hash" size={size} />
);
export const IconImgEdit: React.FC<IconImgUIProps> = ({ size = "base" }) => (
  <IconImg icons={IconImgEditBase} name="icon--edit" size={size} />
);
