import { AxiosResponse } from "axios";
import api from "../api";
import { IUser } from "../models/IUser";
import { IBlockchain } from "../models/IBlockchain";

export interface GetLoginMessageResponse {
  type: string;
  loginMessage: string;
}

export interface LoginResponse {
  type: string;
  token: string;
  user: IUser;
}

export interface GetBlockchainConfigResponse {
  type: string;
  data: IBlockchain[];
}

export interface IVerificationInitData {
  accessToken: string;
  apiUrl: string;
  flowName: string;
  isTest: boolean;
}

export interface GetVerificationInitDataResponse {
  type: string;
  provider: string;
  data: IVerificationInitData;
}

export interface IDataForBlockchain {
  id: string;
  country: number;
  birthDate: number;
  sign: string;
}

export interface GetDataForBlockchainResponse {
  type: string;
  data: IDataForBlockchain;
}

export interface ServiceLoginResponse {
  type: string;
  token: string;
  user: IUser;
}

export interface GetUserDataResponse {
  type: string;
  data: IUser;
}

export interface serviceLoginRedirectResponse {
  passportId: string;
  chainId: string;
  signature: string;
  redirectUrl: string;
  token: string;
  type: string;
}

export default class AuthService {
  // Получение блокчейн-конфигурации
  // /get-blockchain-config (GET)
  static async getBlockchainConfig(): Promise<
    AxiosResponse<GetBlockchainConfigResponse>
  > {
    return api.get<GetBlockchainConfigResponse>("/get-blockchain-config");
  }
  // Получение строки для подписи
  static async getLoginMessage(
    address: string
  ): Promise<AxiosResponse<GetLoginMessageResponse>> {
    const data = new FormData();
    data.append("address", address);
    return api.post<GetLoginMessageResponse>("/get-login-message", data);
  }

  // Логин
  static async login(
    address: string,
    signature: string
  ): Promise<AxiosResponse<LoginResponse>> {
    const data = new FormData();
    data.append("address", address);
    data.append("signature", signature);
    return api.post<LoginResponse>("/login", data);
  }

  // Инициализация верификации
  static async getVerificationInitData(
    passportId: number,
    chainId: number,
    token: string
  ): Promise<AxiosResponse<GetVerificationInitDataResponse>> {
    const data = new FormData();
    data.append("passportId", passportId.toString());
    data.append("chainId", chainId.toString());
    return api.post("/get-verification-init-data", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }

  // Получение данных для занесения в блокчейн
  static async getDataForBlockchain(
    passportId: number,
    chainId: number,
    token: string
  ): Promise<AxiosResponse<GetDataForBlockchainResponse>> {
    const data = new FormData();
    data.append("passportId", passportId.toString());
    data.append("chainId", chainId.toString());
    return api.post<GetDataForBlockchainResponse>(
      "/get-data-for-blockchain",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  // Получение пользовательских данных
  static async getUserData(
    token: string
  ): Promise<AxiosResponse<GetUserDataResponse>> {
    return api.get<GetUserDataResponse>("/get-user-data", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }

  // Сервисная авторизация (предъявление паспорта)
  static async serviceLogin(
    signature: string,
    loginMessage: string
  ): Promise<AxiosResponse<ServiceLoginResponse>> {
    const data = new FormData();
    data.append("signature", signature);
    data.append("loginMessage", loginMessage);
    return api.post<ServiceLoginResponse>("/service-login", data);
  }

  // Получение ссылки для редиректа обратно в сервис
  // /get-service-login-redirect (POST)
  static async serviceLoginRedirect(
    passportId: number,
    chainId: number,
    signature: string,
    redirectUrl: string,
    token: string
  ): Promise<AxiosResponse<serviceLoginRedirectResponse>> {
    const data = new FormData();
    data.append("passportId", passportId.toString());
    data.append("chainId", chainId.toString());
    data.append("signature", signature.toString());
    data.append("redirectUrl", redirectUrl.toString());
    return api.post<serviceLoginRedirectResponse>(
      "/get-service-login-redirect",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
}
