import React, { useState } from "react";
import "./checkup.scss";
import { H1, BodyBoldLarge, ButtonText } from "../../components/UI/Text";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ButtonBase,
  ButtonOutline,
  ButtonSecondary,
} from "../../components/UI/Button";
import AuthService from "../../services/AuthService";
import { WindowPassport } from "../../components/WindowPassport";
import { IPassport } from "../../models/IPassport";
import { Container } from "../../components/Grid/Container/Container";
import { ModalWrapper } from "../../components/Modal/ModalWrapper";
import { ModalAcept } from "../../components/Modal/ModalAcept";
import { Column } from "../../components/Grid/Column/Column";
import { Row } from "../../components/Grid/Row";
import { VerticalOffset } from "../../components/Grid/VerticalOffset";
import { useAppSelector } from "../../app/hooks";

type CheckupProps = {
  onCreate?: () => void;
};

export const Checkup: React.FC<CheckupProps> = ({ onCreate }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirectUrl");
  const loginMessage = searchParams.get("loginMessage");
  const passports = useAppSelector((state) => state.user.passports);

  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [passportId, setPassportId] = useState<number | null>(null);
  const [chainId, setChainId] = useState<number | null>(null);

  const setChainAndPassportIds = (passport: IPassport) => {
    setPassportId(passport.id);
    setChainId(passport.chainId);
    setShowModalConfirm(true);
  };

  const serviceLogin = () => {
    const checkoutToken = localStorage.getItem("checkoutToken");
    const checkoutSignature = localStorage.getItem("checkoutSignature");
    if (
      checkoutToken &&
      checkoutSignature &&
      chainId &&
      passportId !== null &&
      redirectUrl
    ) {
      AuthService.serviceLoginRedirect(
        passportId,
        chainId,
        checkoutSignature,
        redirectUrl,
        checkoutToken
      ).then((response) => {
        if (response.data.type === "success") {
          window.location.href = response.data.redirectUrl;
        }
      });
    } else {
      console.log("NONE OF THE PROPERTIES");
    }
  };

  const onCloseModal = () => {
    setShowModalConfirm(false);
  };

  const isPassports = passports.length > 0;
  return (
    <div className="checkup">
      <ModalWrapper
        isOpen={showModalConfirm}
        onClose={onCloseModal}
        title="Are you sure?"
      >
        <ModalAcept
          desc=""
          buttons={
            <>
              <ButtonOutline onClick={serviceLogin}>Yes</ButtonOutline>
              <ButtonOutline onClick={onCloseModal}>No</ButtonOutline>
            </>
          }
        />
      </ModalWrapper>
      <Container>
        <Row
          rowGap="l"
          xl={{
            rowGap: "2xl",
          }}
          justify="center"
        >
          <Column col={12}>
            <H1>Login with NFT ID</H1>
            <BodyBoldLarge>
              {isPassports ? "Select passport" : "Passports not found"}
            </BodyBoldLarge>
          </Column>
          <Column col={12} md={{ col: 7 }} lg={{ col: 5 }}>
            <VerticalOffset offset="l" md={{ offset: "xl" }}>
              {passports.map((passport: IPassport) => (
                <WindowPassport
                  key={`key-${passport.chainId}-${passport.id}`}
                  passport={passport}
                  onLoginClick={() => setChainAndPassportIds(passport)}
                  onOpenClick={() =>
                    navigate(
                      `/id/${passport.chainId}/${passport.id}?loginMessage=${loginMessage}&redirectUrl=${redirectUrl}`
                    )
                  }
                />
              ))}

              {passports.length > 0 ? (
                <ButtonSecondary
                  onClick={onCreate}
                  size="block"
                  disabled={false}
                >
                  <ButtonText>Create Hashbon Pass</ButtonText>
                </ButtonSecondary>
              ) : (
                <ButtonBase onClick={onCreate} size="block" disabled={false}>
                  <ButtonText>Create Hashbon Pass</ButtonText>
                </ButtonBase>
              )}
            </VerticalOffset>
          </Column>
        </Row>
      </Container>
    </div>
  );
};
