import { IPassport } from "../models/IPassport";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  passports: IPassport[];
  waitingPassports: IPassport[];
  isLoaded: boolean;
  token: string;
  address: string;
}

const initialState: UserState = {
  passports: [],
  waitingPassports: [],
  isLoaded: false,
  token: "",
  address: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setPassports: (state, action: PayloadAction<IPassport[]>) => {
      state.passports = action.payload;
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    addWaitingPassport: (state, action: PayloadAction<IPassport>) => {
      state.waitingPassports.push(action.payload);
    },
  },
});

export const { setPassports, setAddress, setToken } = userSlice.actions;
export default userSlice.reducer;
