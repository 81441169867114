import { useEffect } from "react";
import { connectorLocalStorageKey } from "../app/web3react";
import useWeb3Auth from "../app/useWeb3Auth";
import { ConnectorNames } from "../app/types";

const useEagerConnect = () => {
  const { login } = useWeb3Auth();

  useEffect(() => {
    const connectorId = window.localStorage.getItem(
      connectorLocalStorageKey
    ) as ConnectorNames;
    if (connectorId) {
      if (connectorId === ConnectorNames.WalletConnect) {
        if (window.localStorage.getItem("walletconnect")) {
          login(connectorId);
        }
      } else {
        login(connectorId);
      }
    }
  }, [login]);
};

export default useEagerConnect;
