import React, { useEffect, useState } from "react";
import { useParams, Navigate, Link, useSearchParams } from "react-router-dom";
import "./passport.scss";
import { BodyButtonTablet, H2 } from "../../components/UI/Text";
import { WindowPassportOnly } from "../../components/WindowPassport";
import { Container } from "../../components/Grid/Container";
import { VerticalOffset } from "../../components/Grid/VerticalOffset";
import { Row } from "../../components/Grid/Row";
import { Column } from "../../components/Grid/Column";
import { useAppSelector } from "../../app/hooks";
import { IPassport } from "../../models/IPassport";
import { IconLeftArrow } from "../../components/UI/Icon";
import { IconText } from "../../components/UI/IconText";
import { VerifierFabric } from "../../components/VerifierFabric";
import { VerificationStatus } from "../../models/iVerifiers";

type PassportProps = {};
export const Passport: React.FC<PassportProps> = () => {
  const { passportId, chainId } = useParams();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirectUrl");
  const loginMessage = searchParams.get("loginMessage");
  let backUrl = "/";

  if (redirectUrl && loginMessage) {
    backUrl = `/checkup?loginMessage=${loginMessage}&redirectUrl=${redirectUrl}`;
  }

  const { passports } = useAppSelector((state) => state.user);
  const [passport, setPassport] = useState<IPassport | null>(null);
  const [verificationStatus, setVerificationStatus] =
    useState<VerificationStatus>(null);
  const [isNotfound, setIsNotfound] = useState(false);

  useEffect(() => {
    if (!passports || passports.length === 0) {
      return;
    }

    if (!passportId || !chainId) {
      setIsNotfound(true);
      return;
    }

    const foundPassport = passports.find(
      (passport) =>
        passport.id === parseInt(passportId) &&
        passport.chainId === parseInt(chainId)
    );

    if (foundPassport) {
      if (foundPassport.verificationStatus !== verificationStatus) {
        setVerificationStatus(
          foundPassport.verificationStatus as VerificationStatus
        );
      }

      setPassport(foundPassport);
    } else {
      setIsNotfound(true);
    }
  }, [passports, verificationStatus]);

  if (isNotfound) return <Navigate to="/" />;
  if (!passport)
    return (
      <div className="passport">
        <Container>
          <Row justify="center">
            <Column col={"auto"}>
              <H2>Loading...</H2>
            </Column>
          </Row>
        </Container>
      </div>
    );
  return (
    <div className="passport">
      <Container>
        <Row
          rowGap="l"
          xl={{
            rowGap: "2xl",
          }}
          justify="center"
        >
          <Column col={12}>
            <H2>Passport</H2>

            <div className="passport__link-back">
              <Link to={backUrl}>
                <IconText gap="lg">
                  <IconLeftArrow size="2sm" />
                  <BodyButtonTablet>Back</BodyButtonTablet>
                </IconText>
              </Link>
            </div>
          </Column>
          <Column col={12} md={{ col: 8 }} xl={{ col: 6 }}>
            <div className="passport__body">
              <VerticalOffset offset="xl">
                <WindowPassportOnly passport={passport} />
                <VerifierFabric
                  verificationStatus={verificationStatus}
                  verifier={passport.provider}
                  chainId={passport.chainId}
                  passportId={passport.id}
                />
              </VerticalOffset>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
};
