import { connectorLocalStorageKey } from "../app/web3react";
import { ConnectorNames } from "../app/types";
import { IBlockchain } from "../models/IBlockchain";

type ChangeNetworkStatus = "CHANGED" | "ERROR" | "MANUAL";

export const web3Connector = localStorage.getItem(connectorLocalStorageKey);

export const changeNetwork = (needed: IBlockchain) => {
  const neededHex = `0x${needed.chainId.toString(16)}`;
  const provider = window?.ethereum;
  // Отправлять запрос можно только когда взаимодействие идет через браузер
  return new Promise<ChangeNetworkStatus>((resolve) => {
    if (
      provider &&
      provider.isMetaMask &&
      web3Connector === ConnectorNames.Injected
    ) {
      provider
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: neededHex }],
        })
        .then(() => {
          resolve("CHANGED");
        })
        .catch((error: any) => {
          if (error.code === 4902) {
            resolve(addNetwork(needed));
          }
          resolve("ERROR");
        });
    } else {
      resolve("MANUAL");
    }
  });
};

export const addNetwork = (needed: IBlockchain) => {
  const provider = window?.ethereum;
  const neededHex = `0x${needed.chainId.toString(16)}`;

  return new Promise<ChangeNetworkStatus>((resolve) => {
    if (
      provider &&
      provider.isMetaMask &&
      web3Connector === ConnectorNames.Injected
    ) {
      provider
        .request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: neededHex,
              rpcUrls: [needed.rpcUrl],
              chainName: needed.name,
              nativeCurrency: {
                name: needed.currencySymbol,
                symbol: needed.currencySymbol,
                decimals: 18,
              },
            },
          ],
        })
        .then(() => resolve("CHANGED"))
        .catch(() => resolve("ERROR"));
    } else {
      resolve("MANUAL");
    }
  });
};
