/* eslint-disable */
import React from "react";
import "./verification-status.scss";
import { IconClose, IconPending, IconVersucces } from "../UI/Icon";
import { IconText } from "../UI/IconText";
import { BodySmall } from "../UI/Text";

type VerificationStatusProps = {
  status: string;
};

export const VerificationStatus: React.FC<VerificationStatusProps> = ({
  status,
}) => {
  switch (status) {
    case "NOT_VERIFIED":
      return <div className="verification-status verification-status--error">
        <IconText gap="sm">
          <BodySmall color="error">Not verified</BodySmall>
          <IconClose />
        </IconText>
      </div>
    case "PENDING":
      return <div className="verification-status verification-status--pending">
        <IconText gap="sm">
          <BodySmall color="primary">Pending</BodySmall>
          <IconPending />
        </IconText>
      </div>
    case "SUCCESS":
      return <div className="verification-status verification-status--success">
        <IconText gap="sm">
          <BodySmall color="success">Success</BodySmall>
          <IconVersucces />
        </IconText>
      </div>
  }
  return <BodySmall>{status}</BodySmall>
};
