import { IVerifiersFabricList } from "../models/iVerifiers";
import {
  BabtProvider,
  SumSubProvider,
} from "../components/VerifierFabric/Providers";
import { IconBsc } from "../components/UI/IconImg";
import { IconSumSub } from "../components/UI/IconImg/IconImgs";

export enum Verifiers {
  // eslint-disable-next-line no-unused-vars
  SUMSUB = "SUMSUB",
  // eslint-disable-next-line no-unused-vars
  BABT = "BABT",
}

export const verifierProviders: IVerifiersFabricList = {
  [Verifiers.SUMSUB]: {
    component: SumSubProvider,
    name: "Sumsub",
    icon: IconSumSub,
  },
  [Verifiers.BABT]: {
    component: BabtProvider,
    name: "Binance Account Bound Token",
    icon: IconBsc,
  },
};
