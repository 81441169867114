import React from "react";
import "./row.scss";
import classNames from "classnames";

type RowProps = {
  children?: React.ReactNode;
  align?: Align;
  justify?: Justify;
  direction?: Direction;
  rowGap?: RowGap;
  noGutters?: boolean;
  md?: {
    rowGap?: RowGap;
    direction?: Direction;
    align?: Align;
    justify?: Justify;
  };
  lg?: {
    rowGap?: RowGap;
    direction?: Direction;
    align?: Align;
    justify?: Justify;
  };
  xl?: {
    rowGap?: RowGap;
    direction?: Direction;
    align?: Align;
    justify?: Justify;
  };
  xxl?: {
    rowGap?: RowGap;
    direction?: Direction;
    align?: Align;
    justify?: Justify;
  };
};

type Align = "center" | "flex-end" | "flex-start" | "stretch";
type Justify =
  | "center"
  | "flex-end"
  | "flex-start"
  | "space-between"
  | "space-around";
type RowGap =
  | "4xs"
  | "3xs"
  | "2xs"
  | "xs"
  | "s"
  | "m"
  | "l"
  | "2l"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "xxl"
  | "2xxl"
  | "3xxl"
  | "4xxl";
type Direction = "row" | "column" | "row-reverse" | "column-reverse";

export const Row: React.FC<RowProps> = ({
  align,
  justify,
  direction,
  children,
  rowGap,
  md,
  lg,
  xl,
  xxl,
  noGutters = false,
}) => {
  return (
    <div
      className={`row ${classNames(
        direction ? `row--${direction}` : "",
        noGutters ? "row--no-gutters" : "",
        align ? `row--align-${align}` : "",
        justify ? `row--justify-${justify}` : "",
        rowGap ? `row--row-gap-${rowGap}` : "",
        md?.direction ? `row--md-${md.direction}` : "",
        md?.align ? `row--md-align-${md.align}` : "",
        md?.justify ? `row--md-justify-${md.justify}` : "",
        md?.rowGap ? `row--md-row-gap-${md.rowGap}` : "",
        lg?.rowGap ? `row--lg-row-gap-${lg.rowGap}` : "",
        lg?.direction ? `row--lg-${lg.direction}` : "",
        lg?.align ? `row--lg-align-${lg.align}` : "",
        lg?.justify ? `row--lg-justify-${lg.justify}` : "",
        xl?.rowGap ? `row--xl-row-gap-${xl.rowGap}` : "",
        xl?.direction ? `row--xl-${xl.direction}` : "",
        xl?.align ? `row--xl-align-${xl.align}` : "",
        xl?.justify ? `row--xl-justify-${xl.justify}` : "",
        xxl?.rowGap ? `row--xxl-row-gap-${xxl.rowGap}` : "",
        xxl?.direction ? `row--xxl-${xxl.direction}` : "",
        xxl?.align ? `row--xxl-align-${xxl.align}` : "",
        xxl?.justify ? `row--xxl-justify-${xxl.justify}` : ""
      )}`}
    >
      {children}
    </div>
  );
};
