import React from "react";
import "./modal-switch-chain.scss";
import { BodySmall, ButtonText } from "../../UI/Text";
import { ModalWrapper } from "../ModalWrapper";
import { ButtonOutline } from "../../UI/Button";

type ModalMintSuccessProps = {
  isOpen: boolean;
  onClose: () => void;
  text: string | React.ReactNode;
  title: string;
};

export const ModalSwitchChain: React.FC<ModalMintSuccessProps> = ({
  isOpen,
  onClose,
  text,
  title,
}) => (
  <ModalWrapper isOpen={isOpen} title={title}>
    <div className="modal-switch-chain">
      <BodySmall>{text}</BodySmall>
      <ButtonOutline onClick={onClose} size={"block"}>
        <ButtonText>Ok</ButtonText>
      </ButtonOutline>
    </div>
  </ModalWrapper>
);
