import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/sass/base.scss";
import { Page } from "./components/Page";
import { Main } from "./components/Main";
import { Passport, PassportsPage } from "./routes/Passport";
import { CheckupPage } from "./routes/Checkup";
import { Header } from "./components/Header";
// import { Styles } from "./routes/Styles";
import { AuthorizationProvider } from "./providers/AuthorizationProvider";
import { useAppSelector } from "./app/hooks";
import { ModalNetwork } from "./components/Modal/ModalNetwork";
import { FooterPage } from "./components/Footer";

function App() {
  const isLoader = useAppSelector((state) => state.config.isLoader);
  return (
    <BrowserRouter>
      <AuthorizationProvider>
        <Page>
          <Header />
          {isLoader && (
            <Main>
              <ModalNetwork />
              <Routes>
                <Route path="/" element={<PassportsPage />} />
                <Route path="/id/:chainId/:passportId" element={<Passport />} />
                <Route path="/checkup" element={<CheckupPage />} />

                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>Nothing here!</p>
                    </main>
                  }
                />
              </Routes>
            </Main>
          )}

          <FooterPage
            footerMenus={[
              {
                title: "PRODUCTS",
                itemsMenu: [
                  {
                    linkHref: "https://hashbon.com/",
                    link: "React",
                  },
                  {
                    linkHref: "https://hashbon.com/personal",
                    link: "Quant",
                  },
                  {
                    linkHref: "https://rocket.hashbon.com/",
                    link: "Rocket",
                  },
                  {
                    linkHref: "https://pass.hashbon.com/hashtoken",
                    link: "HASH Token",
                  },
                ],
              },
              {
                title: "RESOURCES",
                itemsMenu: [
                  {
                    linkHref: "https://hashbon.com/legal",
                    link: "Privacy Policy",
                  },
                  {
                    linkHref: "https://hashbon.com/legal-terms",
                    link: "Legal information",
                  },
                ],
              },
            ]}
          />
        </Page>
      </AuthorizationProvider>
    </BrowserRouter>
  );
}

export default App;
