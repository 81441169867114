import "./assets/sass/base.scss";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import { store } from "./app/store";
import { Provider } from "react-redux";
import App from "./App";
import { Web3ReactProvider } from "@web3-react/core";
import { getLibrary } from "./app/web3react";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
      </Web3ReactProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
