import React from "react";
import "./modal-confirm.scss";
import { BodySmall } from "../../UI/Text";
import { ModalWrapper } from "../ModalWrapper";

type ModalConfirmProps = {
  isOpen: boolean;
  onClose?: () => void;
};

export const ModalConfirm: React.FC<ModalConfirmProps> = ({
  isOpen,
  onClose,
}) => (
  <ModalWrapper
    isOpen={isOpen}
    title="Waiting for confirmation"
    onClose={onClose}
    buttonClose={false}
  >
    <div className="modal-confirm">
      <BodySmall>Confirm the action in your wallet</BodySmall>
    </div>
  </ModalWrapper>
);
