import React from "react";
import "./container.scss";
import classNames from "classnames";

type ContainerProps = {
  size?: Size;
  children: React.ReactNode;
  md?: {
    size?: Size;
  };
  lg?: {
    size?: Size;
  };
  xl?: {
    size?: Size;
  };
  xxl?: {
    size?: Size;
  };
};
type Size = "fluid" | "base";

export const Container: React.FC<ContainerProps> = ({
  children,
  size,
  md,
  lg,
  xl,
  xxl,
}) => {
  return (
    <div
      className={`container ${classNames(
        size ? `container--${size}` : "",
        md ? `container--md-${md.size}` : "",
        lg ? `container--lg-${lg.size}` : "",
        xl ? `container--xl-${xl.size}` : "",
        xxl ? `container--xxl-${xxl.size}` : ""
      )}`}
    >
      {children}
    </div>
  );
};
