import React from "react";
import { verifierProviders, Verifiers } from "../../app/verifiers";
import { IconText } from "../UI/IconText";
import { BodySmall } from "../UI/Text";
import { IVerifiersFabric } from "../../models/iVerifiers";

interface IVerificationProviderNameProps {
  provider: Verifiers;
}

export const VerificationProviderName: React.FC<
  IVerificationProviderNameProps
> = ({ provider }) => {
  const verifier: IVerifiersFabric | null =
    provider && verifierProviders.hasOwnProperty(provider)
      ? verifierProviders[provider]
      : null;
  return (
    <IconText gap="sm">
      <BodySmall>{verifier && verifier.name}</BodySmall>
      {verifier && verifier.icon && React.createElement(verifier.icon)}
    </IconText>
  );
};
