import React from "react";
import "./modal-create-passport.scss";
import { BodySmall, ButtonText } from "../../UI/Text";
import { ModalWrapper } from "../ModalWrapper";
import { ButtonBase } from "../../UI/Button";

type ModalMintSuccessProps = {
  isOpen: boolean;
  onClose: () => void;
  onClickButton: () => void;
  disabledButton?: boolean;
  buttonText: string;
  desc: string;
  title: string;
  children: React.ReactNode;
  descNetwork?: React.ReactNode;
};

export const ModalCreatePassport: React.FC<ModalMintSuccessProps> = ({
  isOpen,
  onClose,
  onClickButton,
  desc,
  title,
  buttonText,
  disabledButton = false,
  descNetwork,
  children,
}) => (
  <ModalWrapper isOpen={isOpen} onClose={onClose} title={title}>
    <div className="modal-create-passport">
      <div className="modal-create-passport__desc">
        <BodySmall>{desc}</BodySmall>
      </div>

      <div className="modal-create-passport__body">{children}</div>

      <div className="modal-create-passport__bottom">
        {descNetwork}
        <ButtonBase
          onClick={onClickButton}
          size="block"
          disabled={disabledButton}
        >
          <ButtonText>{buttonText}</ButtonText>
        </ButtonBase>
      </div>
    </div>
  </ModalWrapper>
);
