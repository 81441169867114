import React, { useState } from "react";
import { IPassport } from "../../models/IPassport";
import { ButtonBase, ButtonIcon, ButtonOutline } from "../UI/Button";
import { IconText } from "../UI/IconText";
import { H3, H4, ButtonText, BodySmall } from "../UI/Text";
import { VerificationStatus } from "../VerificationStatus";
import "./window-passport.scss";
import AuthService from "../../services/AuthService";
import useNetworkChanger from "../../hooks/useNetworkChanger";
import AbiID from "../../abi/id.json";
import { getContract2 } from "../../app/web3react";
import { useWeb3React } from "@web3-react/core";
import { Input } from "../UI/Input";
import { useAppSelector } from "../../app/hooks";
import { ModalConfirm } from "../Modal/ModalConfirm";
import { ModalTransaction } from "../Modal/ModalTransaction";
import { DataItem } from "../DataItem";
import {
  IconVerification,
  IconUserId,
  IconEdit,
  IconViewlink,
} from "../UI/Icon";
import { ModalChangeNick } from "../Modal/ModalChangeNick";
import { ModalSuccess } from "../Modal/ModalSuccess";
import { VerificationProviderName } from "../VerificationProviderName";
import { Verifiers } from "../../app/verifiers";
import { ChainIcon } from "../ChainIcon";
import { IVerifiersByChainId } from "../../models/iVerifiers";
import { IBlockchain } from "../../models/IBlockchain";

const nameMapper = new Map<string, string>([
  ["birthDate", "Date of Birth"],
  ["country", "Citizen"],
  ["docType", "Document type"],
  ["name", "Name"],
  ["verificationDate", "Verification date"],
  ["birthDate", "Date of Birth"],
]);

const getName = (key: string): string => {
  return nameMapper.get(key) || key;
};

type WindowPassportProps = {
  passport: IPassport;
  onOpenClick?: () => void;
  onLoginClick?: () => void;
};

export const WindowPassport: React.FC<WindowPassportProps> = ({
  passport,
  onOpenClick,
  onLoginClick,
}) => {
  const { getChainById } = useNetworkChanger();
  return (
    <div className="window-passport">
      <div className="window-passport__header">
        <div className="window-passport__title">
          <IconVerification />
          <H4>Hashbon Pass #{passport.id}</H4>
        </div>
      </div>

      <div className="window-passport__body">
        <ul className="window-passport__list">
          <li className="window-passport__item">
            <BodySmall color="three">Chain:</BodySmall>

            <IconText gap="esm">
              <BodySmall>{getChainById(passport.chainId).name}</BodySmall>
              <div className="window-passport__icon">
                <ChainIcon chainId={passport.chainId} />
              </div>
            </IconText>
          </li>

          <li className="window-passport__item">
            <BodySmall color="three">Nickname:</BodySmall>
            <IconText gap="sm">
              <BodySmall>{passport.nickname}</BodySmall>

              <div className="window-passport__icon">
                <IconUserId />
              </div>
            </IconText>
          </li>
          {passport.data.map((data, index) => (
            <DataItem
              key={`pass-${index}`}
              name={getName(data.name)}
              value={data.value}
            />
          ))}
          <li className="window-passport__item">
            <BodySmall color="three">Verifier:</BodySmall>
            <VerificationProviderName
              provider={passport.provider as Verifiers}
            />
          </li>
          <li className="window-passport__item">
            <BodySmall color="three">Verification status:</BodySmall>
            <VerificationStatus status={passport.verificationStatus} />
          </li>
        </ul>
        {passport.chainData.country && (
          <>
            <H3>Blockchain data:</H3>
            <ul className="window-passport__list">
              <DataItem name={"Citizen"} value={passport.chainData.country} />
              <DataItem
                name={"Date of Birth"}
                value={passport.chainData.birthDate}
              />
              <DataItem name={"Status"} value={"Uploaded"} />
            </ul>
          </>
        )}
        <div className="window-passport__buttons">
          {onLoginClick && (
            <ButtonBase onClick={onLoginClick} size="block" disabled={false}>
              <ButtonText>Login</ButtonText>
            </ButtonBase>
          )}
          {onOpenClick && (
            <ButtonOutline onClick={onOpenClick} size="block" disabled={false}>
              <ButtonText>Open</ButtonText>
            </ButtonOutline>
          )}
        </div>
      </div>
    </div>
  );
};

type WindowPassportOnlyProps = {
  passport: IPassport;
};

export const WindowPassportOnly: React.FC<WindowPassportOnlyProps> = ({
  passport,
}) => {
  const currentBlockchain = useAppSelector(
    (state) => state.config.currentBlockchain
  );
  const blockchains = useAppSelector((state) => state.config.blockchains);
  const verifiersByChain: IVerifiersByChainId = {};
  blockchains.map((chain: IBlockchain) => {
    verifiersByChain[chain.chainId] = chain.verifiers;
  });
  const chainProviders = verifiersByChain[passport.chainId] || null;
  const provider = chainProviders
    ? chainProviders.find((verifier) => {
        return verifier.provider === passport.provider;
      })
    : false;

  const isUploadToBlockchain = provider ? provider.uploadToBlockchain : false;

  const { active, account, library } = useWeb3React();
  const { getChainById, isCorrectChain, changeChain } = useNetworkChanger();
  const [nickname, setNickname] = useState("");
  const [isOpenEditNicknameModal, setIsOpenEditNicknameModal] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenTransactionModal, setIsOpenTransactionModal] = useState(false);
  const [showUpdateNicknameSuccess, setShowUpdateNicknameSuccess] =
    useState(false);
  const [showUploadSuccess, setShowUploadSuccess] = useState(false);
  const [showBurnSuccess, setShowBurnSuccess] = useState(false);
  const token = useAppSelector((state) => state.user.token);

  const onBurn = async () => {
    if (!active || !account || !passport) return;

    if (!isCorrectChain(passport.chainId)) {
      await changeChain(passport.chainId);
    }

    if (!currentBlockchain) return;

    const contract = getContract2(
      currentBlockchain.contractAddress,
      AbiID,
      library,
      account
    );
    setIsOpenConfirmModal(true);
    contract
      .burnPassport(passport.id)
      .then((result: any) => {
        setIsOpenConfirmModal(false);
        setIsOpenTransactionModal(true);
        return result.wait();
      })
      .then(() => {
        setIsOpenTransactionModal(false);
        setShowBurnSuccess(true);
      })
      .catch((e: any) => {
        console.error(e);
        setIsOpenConfirmModal(false);
      });
  };

  const onUpload = async () => {
    if (!active || !account || !passport) return;

    if (!isCorrectChain(passport.chainId)) {
      await changeChain(passport.chainId);
    }

    if (!currentBlockchain) return;

    const response = await AuthService.getDataForBlockchain(
      passport.id,
      passport.chainId,
      token
    );
    const { id, country, birthDate, sign } = response.data.data;
    const contract = getContract2(
      currentBlockchain.contractAddress,
      AbiID,
      library,
      account
    );
    setIsOpenConfirmModal(true);
    contract
      .setPassportData(id, country, birthDate, sign)
      .then((result: any) => {
        setIsOpenConfirmModal(false);
        setIsOpenTransactionModal(true);
        return result.wait();
      })
      .then(() => {
        setIsOpenTransactionModal(false);
        setShowUploadSuccess(true);
      })
      .catch((e: any) => {
        console.log(e);
        setIsOpenConfirmModal(false);
      });
  };
  const onChangeNicknameHandler = (event: any) => {
    setNickname(event.currentTarget.value);
  };
  const updateNickname = async () => {
    if (!active || !account || !passport || !currentBlockchain) return;
    const contract = getContract2(
      currentBlockchain.contractAddress,
      AbiID,
      library,
      account
    );
    setIsOpenConfirmModal(true);
    setIsOpenEditNicknameModal(false);
    contract
      .updateNickname(passport.id, nickname)
      .then((result: any) => {
        setIsOpenConfirmModal(false);
        setIsOpenTransactionModal(true);
        return result.wait();
      })
      .then(() => {
        setIsOpenTransactionModal(false);
        setShowUpdateNicknameSuccess(true);
      })
      .catch((e: any) => {
        console.log(e);
        setIsOpenConfirmModal(false);
      });
  };
  const onEditNickname = () => {
    if (passport.nickname) {
      setNickname(passport.nickname);
    }
    setIsOpenEditNicknameModal(true);
  };
  const onCloseModalEditNickname = () => setIsOpenEditNicknameModal(false);
  const onCloseUpdateNicknameSuccessModal = () =>
    setShowUpdateNicknameSuccess(false);
  const onCloseUploadSuccessModal = () => setShowUploadSuccess(false);

  const onClickViewContract = (e: any): void => {
    e.stopPropagation();
    const chain = getChainById(passport.chainId);
    if (chain) {
      window.open(
        `${chain.explorerUrl}/address/${chain.contractAddress}`,
        "_blank"
      );
    }
  };

  return (
    <div className="window-passport">
      <div className="window-passport__header">
        <div className="window-passport__title">
          <IconVerification />
          <H4>NFT Passport #{passport.id}</H4>
        </div>
      </div>

      <div className="window-passport__body">
        <ul className="window-passport__list">
          <li className="window-passport__item">
            <BodySmall color="three">Chain:</BodySmall>

            <IconText gap="esm">
              <BodySmall>{getChainById(passport.chainId).name}</BodySmall>
              <div className="window-passport__icon">
                <ChainIcon chainId={passport.chainId} />
              </div>
            </IconText>
          </li>

          <li className="window-passport__item">
            <BodySmall color="three">Contract:</BodySmall>

            <button
              className="btn btn--icon"
              type="button"
              onClick={onClickViewContract}
            >
              <BodySmall color={"primary"}>View contract</BodySmall>
              <div className="window-passport__icon">
                <IconViewlink />
              </div>
            </button>
          </li>
          <li className="window-passport__item">
            <BodySmall color="three">Nickname:</BodySmall>
            <IconText gap="sm">
              <BodySmall>{passport.nickname}</BodySmall>

              <ButtonIcon onClick={onEditNickname}>
                <div className="window-passport__icon">
                  <IconEdit />
                </div>
              </ButtonIcon>
            </IconText>
          </li>
          {passport.data.map((data, index) => (
            <DataItem
              key={`pass-${index}`}
              name={getName(data.name)}
              value={data.value}
            />
          ))}
          <li className="window-passport__item">
            <BodySmall color="three">Verifier:</BodySmall>
            <VerificationProviderName
              provider={passport.provider as Verifiers}
            />
          </li>
          <li className="window-passport__item">
            <BodySmall color="three">Verification status:</BodySmall>
            <VerificationStatus status={passport.verificationStatus} />
          </li>
          {passport.BABT && (
            <li className="window-passport__item">
              <BodySmall color="three">Binance Account Bound Token:</BodySmall>
              <BodySmall>{passport.BABT}</BodySmall>
            </li>
          )}
        </ul>
        {passport.chainData.country && (
          <>
            <H3>Blockchain data:</H3>
            <ul className="window-passport__list">
              <DataItem name={"Citizen"} value={passport.chainData.country} />
              <DataItem
                name={"Date of Birth"}
                value={passport.chainData.birthDate}
              />
              <DataItem name={"Status"} value={"Uploaded"} />
            </ul>
          </>
        )}
        {isUploadToBlockchain &&
          passport.verificationStatus === "SUCCESS" &&
          passport.chainData.country === null && (
            <ButtonOutline size="block" onClick={onUpload}>
              Upload to Blockchain
            </ButtonOutline>
          )}
        <ButtonOutline size="block" onClick={onBurn}>
          Burn
        </ButtonOutline>
      </div>
      <ModalChangeNick
        isOpen={isOpenEditNicknameModal}
        onClose={onCloseModalEditNickname}
        buttonClick={updateNickname}
        title="Edit nickname"
      >
        <Input
          name="nickname"
          type="text"
          label="Nickname"
          value={nickname}
          placeholder={"Enter a new nickname"}
          onChange={onChangeNicknameHandler}
        />
      </ModalChangeNick>

      <ModalConfirm isOpen={isOpenConfirmModal} />

      <ModalTransaction isOpen={isOpenTransactionModal} />

      <ModalSuccess
        isOpen={showUpdateNicknameSuccess}
        onClose={onCloseUpdateNicknameSuccessModal}
        title="Transaction successful!"
        desc="Your nickname has been updated. It may not appear immediately, it's worth waiting a bit."
        buttonText="Done!"
      />

      <ModalSuccess
        isOpen={showUploadSuccess}
        onClose={onCloseUploadSuccessModal}
        title="Transaction successful!"
        desc="Information has been uploaded successfully. It may not appear immediately, it's worth waiting a bit."
        buttonText="Done!"
      />

      <ModalSuccess
        isOpen={showBurnSuccess}
        onClose={() => setShowBurnSuccess(false)}
        title="Transaction successful!"
        desc="Your passport has been successfully burnt"
        buttonText="Done!"
      />
    </div>
  );
};
