import React from "react";
import "./select-custom.scss";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { IconArrowDown } from "../Icon";
import { BodySmall } from "../Text";

type SelectCustomProps = {
  label?: string;
  options: React.ReactNode[] | any;
  defaultValue: React.ReactNode | any;
  placeholder?: string;
  onChange?: (e: any) => void;
};

export const SelectCustom: React.FC<SelectCustomProps> = ({
  label,
  options,
  defaultValue,
  placeholder,
  onChange,
  ...props
}) => (
  <div className="select-custom">
    <div className="select-custom__label">
      <BodySmall>{label}</BodySmall>
    </div>

    <Select
      className="select-custom__body"
      options={options}
      defaultValue={defaultValue}
      classNamePrefix="select-custom"
      components={{ DropdownIndicator }}
      placeholder={placeholder}
      onChange={onChange}
      {...props}
    />
  </div>
);

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => (
  <components.DropdownIndicator {...props}>
    <IconArrowDown size="3sm" />
  </components.DropdownIndicator>
);
