import React from "react";
import cn from "classnames";
import "./tooltip-content.scss";

type TooltipContentProps = {
  children: React.ReactNode;
  position?: Position;
  size?: Size;
};

type Position = "left" | "right" | "bottom" | "top";
export type Size = "auto" | "base";

export const TooltipContent: React.FC<TooltipContentProps> = ({
  children,
  position,
  size,
}) => (
  <div
    className={cn(
      "tooltip-content",
      position ? ` tooltip-content--${position}` : "",
      size ? ` tooltip-content--size-${size}` : ""
    )}
  >
    <div className="tooltip-content__body">{children}</div>
    <div className="tooltip-content__visual"></div>
  </div>
);
