import React from "react";
import "./icon-text.scss";

type IconTextProps = {
  children: React.ReactNode;
  gap?: Gap;
};

type Gap = "esm" | "sm" | "m" | "lg" | "xl";

export const IconText: React.FC<IconTextProps> = ({ gap = "m", children }) => (
  <div className={`icon-text ${gap ? `icon-text--gap-${gap}` : ""}`}>
    {children}
  </div>
);
