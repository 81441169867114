import React from "react";
import Lottie from "lottie-react";
import loadAnimation from "../Modal/ModalTransaction/loading-anim.json";

export const VerificationPending = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p>Your data is being verified</p>
      <br />
      <Lottie
        animationData={loadAnimation}
        loop={true}
        renderer={"svg"}
        className="modal-transaction__spinner"
      />
    </div>
  );
};
