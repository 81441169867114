import { Contract, ethers } from "ethers";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { ConnectorNames } from "./types";
import { Web3Provider, JsonRpcSigner } from "@ethersproject/providers";
import { supportedChainIds } from "../features/wallet/connectors";

const POLLING_INTERVAL = 12000;

type RPCURLS = {
  [key: number]: string;
};

export const RPC_URLS: RPCURLS = {
  1: "https://eth.getblock.io/mainnet/?api_key=139f8680-11ae-492f-bffa-63698a733319",
  3: "https://eth.getblock.io/ropsten/?api_key=4c7e9645-73a8-408c-8c93-7fd87de4835c",
  4: "https://eth.getblock.io/rinkeby/?api_key=4c7e9645-73a8-408c-8c93-7fd87de4835c",
  56: "https://bsc-dataseed.binance.org/",
  97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  137: "https://eth.getblock.io/polygon/?api_key=4c7e9645-73a8-408c-8c93-7fd87de4835c",
};

export const connectorLocalStorageKey = "connectorId";

const injected = new InjectedConnector({ supportedChainIds });

const walletconnect = new WalletConnectConnector({
  rpc: RPC_URLS,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

// eslint-disable-next-line no-unused-vars
export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
};

export const getLibrary = (provider: any): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};

export const signMessage = async (
  provider: any,
  account: string,
  message: string
): Promise<string> => {
  /**
   * Wallet Connect does not sign the message correctly unless you use their method
   * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
   */
  if (provider.provider?.wc) {
    const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message));
    return provider.provider?.wc.signPersonalMessage([wcMessage, account]);
  }

  return provider.getSigner(account).signMessage(message);
};

export const getSimpleRpcProvider = (chainId = 1) =>
  new ethers.providers.JsonRpcProvider(RPC_URLS[chainId]);

export const getContract = (
  abi: any,
  address: string,
  chainId = 1,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  const signerOrProvider = signer || getSimpleRpcProvider(chainId);
  return new ethers.Contract(address, abi, signerOrProvider);
};

export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

export const getContract2 = (
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract => {
  // if (!isAddress(address) || address === AddressZero) {
  //   throw Error(`Invalid 'address' parameter '${address}'.`);
  // }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any
  );
};

export const toPowAndHex = (
  value: number | string,
  pow: number | string | null = 18
) => {
  const parts = value.toString().split(".");
  const cf = parts.length < 2 ? 1 : 10 ** parts[1].length;
  return `0x${Math.round(
    Number(value) * cf * (10 ** Number(pow) / cf)
  ).toString(16)}`;
};
