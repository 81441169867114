import React from "react";
import "./modal-transaction.scss";
import Lottie from "lottie-react";
import loadAnimation from "./loading-anim.json";
import { ModalWrapper } from "../ModalWrapper";

type ModalTransactionProps = {
  isOpen: boolean;
  onClose?: () => void;
};

export const ModalTransaction: React.FC<ModalTransactionProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      title="Transaction in process"
      center
      buttonClose={false}
    >
      <div className="modal-transaction">
        <Lottie
          animationData={loadAnimation}
          loop={true}
          renderer={"svg"}
          className="modal-transaction__spinner"
        />
      </div>
    </ModalWrapper>
  );
};
