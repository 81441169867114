import React from "react";
import "./modal-wrapper.scss";
import Modal from "react-modal";
import { ButtonIcon } from "../../UI/Button";
import { IconClose } from "../../UI/Icon";
import { H4 } from "../../UI/Text";

const modalCustomStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1050,
  },
};

type ModalWrapperProps = {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  size?: Size;
  center?: boolean;
  buttonClose?: boolean;
};

export type Size = "base" | "sm";

export const ModalWrapper: React.FC<ModalWrapperProps> = ({
  isOpen,
  title,
  onClose,
  children,
  size = "base",
  center = false,
  buttonClose = true,
}) => {
  return (
    <>
      <Modal
        className={`modal-wrapper 
      ${size ? `modal-wrapper--size-${size}` : ""}
      ${typeof onClose !== "function" ? "modal-wrapper--no-close" : ""}
      ${center ? "modal-wrapper--center" : ""}
      `}
        shouldCloseOnOverlayClick={true}
        isOpen={isOpen}
        style={modalCustomStyle}
        contentLabel={title}
        ariaHideApp={false}
        onRequestClose={onClose}
        bodyOpenClassName={"modal-body"}
      >
        <div className="modal-wrapper__content">
          <div className="modal-wrapper__header">
            {title && (
              <div className="modal-wrapper__header-title">
                <H4>{title}</H4>
              </div>
            )}
            {buttonClose && (
              <div className="modal-wrapper__header-close">
                <ButtonIcon onClick={onClose}>
                  <IconClose />
                </ButtonIcon>
              </div>
            )}
          </div>
          <div className="modal-wrapper__body">{children}</div>
        </div>
      </Modal>
    </>
  );
};
