import React from "react";
import "./data-item.scss";
import { BodyMedium } from "../UI/Text";

type DataItemProps = {
  name: string;
  value: string | number | null;
};

export const DataItem: React.FC<DataItemProps> = ({ name, value }) => {
  return (
    <li className="data-item">
      <BodyMedium color="secondary">{name}:</BodyMedium>
      <BodyMedium>{value}</BodyMedium>
    </li>
  );
};
