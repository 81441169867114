import { configureStore } from "@reduxjs/toolkit";
import configReducer from "./ConfigSlice";
import userReducer from "./UserSlice";
import networkChangerReducer from "./NetworkChangerSlice";

export const store = configureStore({
  reducer: {
    config: configReducer,
    user: userReducer,
    networkChanger: networkChangerReducer,
    // ... transaction: transactionReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
