import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import "./tooltips.scss";
import classNames from "classnames";
import { TooltipContent, Size } from "../TooltipContent";

export type TooltipsProps = {
  placement?: Placement;
  children: React.ReactNode;
  content: React.ReactNode;
  size?: Size;
};

export type Placement = "left" | "right" | "bottom" | "top";

export const Tooltips: React.FC<TooltipsProps> = ({
  placement,
  content,
  children,
  size,
}) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const tooltipRef = useRef<HTMLButtonElement>(null);
  const tooltipContentRef = useRef<HTMLDivElement>(null);
  const { width, height } = useWindowDimensions();
  const [position, setPosition] = useState(placement);
  useEffect(() => {
    calculateTooltipPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  useEffect(() => {
    calculateTooltipPosition();
    window.addEventListener("scroll", calculateTooltipPosition);

    return () => {
      window.removeEventListener("scroll", calculateTooltipPosition);
    };
  });

  const calculateTooltipPosition = () => {
    if (tooltipRef.current && tooltipContentRef.current) {
      const elementPosition = tooltipRef.current?.getBoundingClientRect();
      const elementContetnPosition =
        tooltipContentRef.current?.getBoundingClientRect();
      const horizontalPosition =
        (width || 0) - elementPosition.x - elementPosition.width;
      const verticalPosition =
        (height || 0) - elementPosition.y - elementPosition.height;

      const getVerticalPosition = (pos: Placement) => {
        if (pos === "top") {
          if (elementPosition.y >= elementContetnPosition.height + 60) {
            return "top";
          }
          return "bottom";
        }

        if (pos === "bottom") {
          if (verticalPosition >= elementContetnPosition.height) {
            return "bottom";
          }
          return "top";
        }
      };

      switch (placement) {
        case "right":
        case "left":
          if (horizontalPosition <= 280) {
            setPosition(getVerticalPosition("top"));
          } else {
            setPosition(placement);
          }
          break;

        case "bottom":
        case "top":
          setPosition(getVerticalPosition(placement));
          break;
      }
    }
  };

  const onClick = () => {
    calculateTooltipPosition();
    setOpenTooltip(!openTooltip);
  };

  return (
    <div className={`tooltips`}>
      <button
        ref={tooltipRef}
        type="button"
        className="tooltips__button"
        onClick={onClick}
      >
        {children}
      </button>

      <div
        ref={tooltipContentRef}
        className={`tooltips__content ${classNames(
          placement ? `tooltips__content--${position}` : "",
          openTooltip ? "tooltips__content--open" : ""
        )}`}
      >
        <TooltipContent position={position} size={size}>
          {content}
        </TooltipContent>
      </div>
    </div>
  );
};
