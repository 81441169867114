import React from "react";
import "./modal-success.scss";
import Lottie from "lottie-react";
import { BodySmall, ButtonText } from "../../UI/Text";
import { ModalWrapper } from "../ModalWrapper";
import { ButtonOutline } from "../../UI/Button";
import loadCheckAnimAnimation from "./load-check-anim.json";

type ModalMintSuccessProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  desc: string;
  buttonText: string;
};

export const ModalSuccess: React.FC<ModalMintSuccessProps> = ({
  isOpen,
  onClose,
  title,
  desc,
  buttonText,
}) => (
  <ModalWrapper
    isOpen={isOpen}
    title={title}
    center
    buttonClose={false}
    onClose={onClose}
  >
    <div className="modal-success">
      <BodySmall>{desc}</BodySmall>
      <Lottie
        className="modal-success__img"
        animationData={loadCheckAnimAnimation}
        renderer={"svg"}
      />
      <ButtonOutline onClick={onClose} size={"block"}>
        <ButtonText>{buttonText}</ButtonText>
      </ButtonOutline>
    </div>
  </ModalWrapper>
);
