import React from "react";
import "./header.scss";
import { Logo } from "../Logo/Logo";
import { ConnectToWalletButton } from "../ConnectToWalletButton";
import useEagerConnect from "../../hooks/useEagerConnect";
import { Container } from "../Grid/Container/Container";
import { Column } from "../Grid/Column/Column";
import { Row } from "../Grid/Row";

type HeaderProps = {};

export const Header: React.FC<HeaderProps> = () => {
  useEagerConnect();
  return (
    <header className="header">
      <Container>
        <Row align="center" justify="space-between">
          <Column col={"auto"}>
            <Logo />
          </Column>

          <Column col={"auto"}>
            <ConnectToWalletButton />
          </Column>
        </Row>
      </Container>
    </header>
  );
};
