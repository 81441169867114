import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBlockchain } from "../models/IBlockchain";

interface UserState {
  blockchains: IBlockchain[];
  currentBlockchain: IBlockchain | null;
  isLoader: boolean;
}

const initialState: UserState = {
  blockchains: [],
  currentBlockchain: null,
  isLoader: false,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setBlockchains: (state, action: PayloadAction<IBlockchain[]>) => {
      state.blockchains = action.payload;
    },
    setIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoader = action.payload;
    },
    setCurrentBlockchain: (state, action: PayloadAction<IBlockchain>) => {
      state.currentBlockchain = action.payload;
    },
  },
});

export const { setBlockchains, setIsLoaded, setCurrentBlockchain } =
  configSlice.actions;
export default configSlice.reducer;
