import React from "react";
import { Icon, Size } from "./Icon";
import IconEditBase from "../../../assets/icon/icon--edit.svg";
import IconDownloadBase from "../../../assets/icon/icon--download.svg";
import IconCloseBase from "../../../assets/icon/icon--menu-close.svg";
import IconArrowDownBase from "../../../assets/icon/icon--arrow-down.svg";
import IconTooltipInfoBase from "../../../assets/icon/icon--tooltip-info.svg";
import IconVerificationBase from "../../../assets/icon/icon--verification.svg";
import IconUserIdBase from "../../../assets/icon/icon--user-id.svg";
import IconPendingBase from "../../../assets/icon/icon--pending.svg";
import IconLeftArrowBase from "../../../assets/icon/icon--left-arrow.svg";
import IconViewlinkBase from "../../../assets/icon/icon--viewlink.svg";
import IconVersuccesBase from "../../../assets/icon/icon--versucces.svg";

type IconUIProps = {
  size?: Size;
};

export const IconVersucces: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconVersuccesBase} name="icon--versucces" size={size} />
);

export const IconViewlink: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconViewlinkBase} name="icon--viewlink" size={size} />
);

export const IconLeftArrow: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconLeftArrowBase} name="icon--left-arrow" size={size} />
);

export const IconPending: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconPendingBase} name="icon--pending" size={size} />
);

export const IconUserId: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon
    icons={IconUserIdBase}
    name="icon--user-id"
    size={size}
    fill="primary"
  />
);

export const IconVerification: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon
    icons={IconVerificationBase}
    name="icon--verification"
    size={size}
    fill="primary"
  />
);

export const IconTooltipInfo: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconTooltipInfoBase} name="icon--tooltip-info" size={size} />
);

export const IconClose: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconCloseBase} name="icon--menu-close" size={size} />
);

export const IconDownload: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconDownloadBase} name="icon--download" size={size} />
);

export const IconEdit: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon icons={IconEditBase} name="icon--edit" size={size} />
);

export const IconArrowDown: React.FC<IconUIProps> = ({ size = "base" }) => (
  <Icon
    icons={IconArrowDownBase}
    name="icon--arrow-down"
    size={size}
    stroke="white"
  />
);
