import React from "react";
import "./logo.scss";
import ImgLogoTwo from "../../assets/images/img-logo-large-v2.svg";
import ImgLogoShort from "../../assets/images/img-logo-short.svg";
import { Link } from "react-router-dom";

type LogoProps = {
  img?: string;
};

export const Logo: React.FC<LogoProps> = ({ img = ImgLogoTwo }) => {
  return (
    <Link to="/" className="logo">
      <picture>
        <source srcSet={ImgLogoShort} media="(max-width: 767px)" />
        <img src={img} alt="Hashbo Rocket" />
      </picture>
    </Link>
  );
};

export const LogoFooter: React.FC<LogoProps> = ({ img = ImgLogoTwo }) => {
  return (
    <Link to="/" className="logo logo--footer">
      <picture>
        <img src={img} alt="Hashbo Rocket" />
      </picture>
    </Link>
  );
};
