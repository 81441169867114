import React from "react";
import "./vertical-offset.scss";
import classNames from "classnames";

type VerticalOffsetProps = {
  offset?: OffsetSize;
  children?: React.ReactNode;
  md?: {
    offset?: OffsetSize;
  };
  lg?: {
    offset?: OffsetSize;
  };
  xl?: {
    offset?: OffsetSize;
  };
  xxl?: {
    offset?: OffsetSize;
  };
};

type OffsetSize =
  | "4xs"
  | "3xs"
  | "2xs"
  | "xs"
  | "2s"
  | "1s"
  | "s"
  | "m"
  | "l"
  | "2l"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "xxl"
  | "2xxl"
  | "3xxl"
  | "4xxl";

export const VerticalOffset: React.FC<VerticalOffsetProps> = ({
  offset,
  children,
  md,
  lg,
  xl,
  xxl,
}) => {
  return (
    <div
      className={`vertical-offset ${classNames(
        `vertical-offset--${offset}`,
        md ? `vertical-offset--md-${md.offset}` : "",
        lg ? `vertical-offset--lg-${lg.offset}` : "",
        xl ? `vertical-offset--xl-${xl.offset}` : "",
        xxl ? `vertical-offset--xxl-${xxl.offset}` : ""
      )}`}
    >
      {children}
    </div>
  );
};
