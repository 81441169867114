import React from "react";
import "./link.scss";

type LinkProps = {
  href: string;
  children: React.ReactNode;
  target?: "no-target" | "target";
};

export const Link: React.FC<LinkProps> = ({
  href,
  children,
  target = "no-target",
}) => (
  <>
    {target === "target" && (
      <a className="link" href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    )}

    {target === "no-target" && (
      <a className="link" href={href}>
        {children}
      </a>
    )}
  </>
);
