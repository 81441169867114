import React from "react";
import "./footer.scss";
import { Container } from "../Grid/Container";
import { Row } from "../Grid/Row";
import { Column } from "../Grid/Column/Column";
import { BodyXSmall, BodyBoldSmall } from "../UI/Text";
import { Link } from "../UI/Link/Link";
import { LogoFooter } from "../Logo/Logo";
import { MenuSocial } from "../MenuSocial/MenuSocial";
import {
  IconCoingekoHash,
  IconMarketHash,
  IconMediumHash,
  IconRoboHash,
  IconTelegramHash,
  IconTwitterHash,
} from "../UI/IconImg";

type FooterProps = {
  children: React.ReactNode;
};

export const Footer: React.FC<FooterProps> = ({ children }) => (
  <div className="footer">{children}</div>
);

type FooterPageProps = {
  footerMenus: {
    title: string;
    itemsMenu: {
      linkHref: string;
      link: string;
    }[];
  }[];
};

export const FooterPage: React.FC<FooterPageProps> = ({ footerMenus = [] }) => (
  <div className="footer footer--page">
    <Container>
      <Row>
        <Column col={12}>
          <div className="footer__body">
            <div className="footer__left">
              <div className="footer__desc">
                <LogoFooter />

                <BodyXSmall>© 2016-2022, Hashbon FiRe</BodyXSmall>

                <Link href="mailto:bd@hashbon.com" target="target">
                  <BodyXSmall>bd@hashbon.com</BodyXSmall>
                </Link>
              </div>
            </div>

            <nav className="footer__nav">
              {footerMenus.map((item, index) => (
                <FooterMenu
                  title={item.title}
                  items={item.itemsMenu}
                  key={index}
                />
              ))}
            </nav>

            <div className="footer__right">
              <div className="footer__social">
                <BodyBoldSmall>STAY CONNECTED</BodyBoldSmall>

                <MenuSocial
                  items={[
                    {
                      href: "https://coinmarketcap.com/currencies/hash-token/",
                      icon: <IconMarketHash size="s" />,
                    },
                    {
                      href: "https://www.coingecko.com/en/coins/hash-token/historical_data/usd#panel",
                      icon: <IconCoingekoHash size="s" />,
                    },
                    {
                      href: "https://twitter.com/hashbon",
                      icon: <IconTwitterHash size="s" />,
                    },
                    {
                      href: "https://www.reddit.com/r/hashbon/",
                      icon: <IconRoboHash size="s" />,
                    },
                    {
                      href: "https://t.me/hashbon_com",
                      icon: <IconTelegramHash size="s" />,
                    },
                    {
                      href: "https://hashbon.medium.com/",
                      icon: <IconMediumHash size="s" />,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </Column>
      </Row>
      <div className="footer__text">
        <BodyXSmall>
          We do not have access to your personal data and do not take part in
          any data storage, all the information you provide goes directly to the
          verified KYC service provider which complies with GDPR requirements
        </BodyXSmall>
      </div>
    </Container>
  </div>
);

type FooterMenuProps = {
  title: string;
  items: {
    linkHref: string;
    link: string;
  }[];
};

const FooterMenu: React.FC<FooterMenuProps> = ({ title, items = [] }) => {
  return (
    <div className="footer__menu">
      <BodyBoldSmall>{title}</BodyBoldSmall>
      <ul className="footer__menu-list">
        {items.map((item, index) => (
          <li className="footer__menu-item" key={index}>
            <Link href={item.linkHref}>
              <BodyXSmall>{item.link}</BodyXSmall>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
