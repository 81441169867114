import React, { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import AuthService from "../../services/AuthService";
import { setAddress, setPassports, setToken } from "../../app/UserSlice";
import {
  setBlockchains,
  setCurrentBlockchain,
  setIsLoaded,
} from "../../app/ConfigSlice";
import { useAppSelector } from "../../app/hooks";

interface IAuthorizationProvider {
  children: any;
}

export const AuthorizationProvider: React.FC<IAuthorizationProvider> = ({
  children,
}) => {
  const { active, chainId, account } = useWeb3React();
  const dispatch = useDispatch();
  const UPDATE_TIME = 3000;

  const isLoader = useAppSelector((state) => state.config.isLoader);
  const blockchains = useAppSelector((state) => state.config.blockchains);
  const token = useAppSelector((state) => state.user.token);
  const address = useAppSelector((state) => state.user.address);
  let updater: any;

  const fetchUserData = () => {
    if (!token) return;
    AuthService.getUserData(token)
      .then((response) => {
        if (response.data.type === "success") {
          dispatch(setPassports(response.data.data.passports));
          dispatch(setAddress(response.data.data.address));
        } else {
          if (updater) {
            clearInterval(updater);
          }
        }
      })
      .catch(() => {
        localStorage.removeItem("token");
        dispatch(setPassports([]));
        dispatch(setAddress(""));
        dispatch(setToken(""));
        if (updater) {
          clearInterval(updater);
        }
      });
  };

  useEffect(() => {
    AuthService.getBlockchainConfig().then((response) => {
      dispatch(setBlockchains(response.data.data));
      dispatch(setIsLoaded(true));
    });
  }, []);

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    if (userToken && active && account && isLoader) {
      dispatch(setToken(userToken));
    }
  }, [active, account, isLoader]);

  useEffect(() => {
    if (updater) {
      clearInterval(updater);
    }
    if (token) {
      fetchUserData();
      updater = setInterval(fetchUserData, UPDATE_TIME);
    }
    return () => {
      clearInterval(updater);
    };
  }, [token]);

  useEffect(() => {
    if (
      active &&
      account &&
      address &&
      account.toLowerCase() !== address.toLowerCase()
    ) {
      localStorage.removeItem("token");
      dispatch(setPassports([]));
      dispatch(setAddress(""));
      dispatch(setToken(""));
      if (updater) {
        clearInterval(updater);
      }
    }
  }, [account]);

  useEffect(() => {
    if (isLoader) {
      const currentBlockchain = blockchains.find(
        (blockchain) => blockchain.chainId === chainId
      );
      if (currentBlockchain) {
        dispatch(setCurrentBlockchain(currentBlockchain));
      }
    }
  }, [active, chainId, isLoader]);

  return children;
};
