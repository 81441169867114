import React from "react";
import "./main.scss";

type MainProps = {
  children: React.ReactNode;
};

export const Main: React.FC<MainProps> = ({ children }) => (
  <main className="main">{children}</main>
);
