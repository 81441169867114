import React from "react";
import { VerificationPending } from "../VerificationPending";
import {
  IVerifiersFabric,
  VerificationProviderProps,
  VerificationStatus,
} from "../../models/iVerifiers";
import { verifierProviders } from "../../app/verifiers";

export interface VerifierFabricProps extends VerificationProviderProps {
  verificationStatus: VerificationStatus;
  verifier: string;
}

export const VerifierFabric = (props: VerifierFabricProps) => {
  const { chainId, passportId, verifier, verificationStatus } = props;
  const currentVerifier: IVerifiersFabric | null =
    verifierProviders && verifierProviders[verifier]
      ? verifierProviders[verifier]
      : null;

  if (verificationStatus === "PENDING") {
    return <VerificationPending />;
  }

  if (verificationStatus !== "SUCCESS" && currentVerifier) {
    const verifierComponent = currentVerifier.component;
    return React.createElement(verifierComponent, { chainId, passportId });
  }
  return <></>;
};
