import React from "react";
import "./text.scss";

type TextProps = {
  variant?: Variant;
  color?: Colors;
  children: React.ReactNode;
  tag?: Tag;
};

type Tag = "H1" | "H2" | "H3" | "H4" | "H5" | "div" | "p" | "span";

type Variant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h5-bold"
  | "button"
  | "urls-menu"
  | "urls-menu-secendary"
  | "urls-menu-medium"
  | "mobile-body-regular"
  | "mobile-body-bold"
  | "caption"
  | "counter"
  | "body-small"
  | "body-bold-small"
  | "body-large"
  | "body-bold-large"
  | "body-medium"
  | "body-bold-medium"
  | "body-xsmall"
  | "button-tablet";

type Colors =
  | "primary"
  | "secondary"
  | "three"
  | "white"
  | "block-stroke"
  | "error"
  | "success";

const Text: React.FC<TextProps> = ({ variant, color, tag = "p", children }) => (
  <>
    {tag === "H1" && (
      <h1
        className={`text ${variant ? `text--size-${variant}` : ""} 
        ${color ? `text--color-${color}` : ""}`}
      >
        {children}
      </h1>
    )}
    {tag === "H2" && (
      <h2
        className={`text ${variant ? `text--size-${variant}` : ""}
        ${color ? `text--color-${color}` : ""}`}
      >
        {children}
      </h2>
    )}
    {tag === "H3" && (
      <h3
        className={`text ${variant ? `text--size-${variant}` : ""}
        ${color ? `text--color-${color}` : ""}`}
      >
        {children}
      </h3>
    )}
    {tag === "H4" && (
      <h4
        className={`text ${variant ? `text--size-${variant}` : ""}
        ${color ? `text--color-${color}` : ""}`}
      >
        {children}
      </h4>
    )}
    {tag === "H5" && (
      <h5
        className={`text ${variant ? `text--size-${variant}` : ""}
        ${color ? `text--color-${color}` : ""}`}
      >
        {children}
      </h5>
    )}
    {tag === "div" && (
      <div
        className={`text ${variant ? `text--size-${variant}` : ""}
        ${color ? `text--color-${color}` : ""}`}
      >
        {children}
      </div>
    )}
    {tag === "p" && (
      <p
        className={`text ${variant ? `text--size-${variant}` : ""}
        ${color ? `text--color-${color}` : ""}`}
      >
        {children}
      </p>
    )}
    {tag === "span" && (
      <span
        className={`text ${variant ? `text--size-${variant}` : ""}
        ${color ? `text--color-${color}` : ""}`}
      >
        {children}
      </span>
    )}
  </>
);

type TextIProps = {
  color?: Colors;
  children: React.ReactNode;
  tag?: Tag;
};

export const H1: React.FC<TextIProps> = ({ color, children }) => (
  <Text variant="h1" color={color} tag="H1">
    {children}
  </Text>
);
export const H2: React.FC<TextIProps> = ({ color, children }) => (
  <Text variant="h2" color={color} tag="H2">
    {children}
  </Text>
);
export const H3: React.FC<TextIProps> = ({ color, children }) => (
  <Text variant="h3" color={color} tag="H3">
    {children}
  </Text>
);
export const H4: React.FC<TextIProps> = ({ color, children }) => (
  <Text variant="h4" color={color} tag="H4">
    {children}
  </Text>
);
export const H5: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="h5" color={color} tag={tag}>
    {children}
  </Text>
);
export const H5Bold: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="h5-bold" color={color} tag={tag}>
    {children}
  </Text>
);
export const ButtonText: React.FC<TextIProps> = ({ color, children }) => (
  <Text variant="button" color={color} tag="span">
    {children}
  </Text>
);
export const UrlsMenu: React.FC<TextIProps> = ({ color, children }) => (
  <Text variant="urls-menu" color={color} tag="p">
    {children}
  </Text>
);
export const UrlsMenuSecondary: React.FC<TextIProps> = ({
  color,
  children,
}) => (
  <Text variant="urls-menu-secendary" color={color} tag="p">
    {children}
  </Text>
);
export const UrlsMenuMedium: React.FC<TextIProps> = ({ color, children }) => (
  <Text variant="urls-menu-medium" color={color} tag="p">
    {children}
  </Text>
);
export const MobileBodyRegular: React.FC<TextIProps> = ({
  color,
  children,
}) => (
  <Text variant="mobile-body-regular" color={color} tag="p">
    {children}
  </Text>
);
export const MobileBodyBold: React.FC<TextIProps> = ({ color, children }) => (
  <Text variant="mobile-body-bold" color={color} tag="p">
    {children}
  </Text>
);
export const Caption: React.FC<TextIProps> = ({ color, children }) => (
  <Text variant="caption" color={color} tag="p">
    {children}
  </Text>
);
export const Counter: React.FC<TextIProps> = ({ color, children }) => (
  <Text variant="counter" color={color} tag="span">
    {children}
  </Text>
);
export const BodySmall: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="body-small" color={color} tag={tag}>
    {children}
  </Text>
);
export const BodyBoldSmall: React.FC<TextIProps> = ({
  color,
  children,
  tag,
}) => (
  <Text variant="body-bold-small" color={color} tag={tag}>
    {children}
  </Text>
);
export const BodyLarge: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="body-large" color={color} tag={tag}>
    {children}
  </Text>
);
export const BodyBoldLarge: React.FC<TextIProps> = ({
  color,
  children,
  tag,
}) => (
  <Text variant="body-bold-large" color={color} tag={tag}>
    {children}
  </Text>
);
export const BodyMedium: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="body-medium" color={color} tag={tag}>
    {children}
  </Text>
);
export const BodyBoldMedium: React.FC<TextIProps> = ({
  color,
  children,
  tag,
}) => (
  <Text variant="body-bold-medium" color={color} tag={tag}>
    {children}
  </Text>
);
export const BodyXSmall: React.FC<TextIProps> = ({ color, children, tag }) => (
  <Text variant="body-xsmall" color={color} tag={tag}>
    {children}
  </Text>
);
export const BodyButtonTablet: React.FC<TextIProps> = ({
  color,
  children,
  tag,
}) => (
  <Text variant="button-tablet" color={color} tag={tag}>
    {children}
  </Text>
);
