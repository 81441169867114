import React from "react";
import "./menu-social.scss";

type MenuSocialProps = {
  items: {
    href: string;
    icon: React.ReactNode;
  }[];
};

export const MenuSocial: React.FC<MenuSocialProps> = ({ items = [] }) => (
  <ul className="menu-social">
    {items.map((item, index) => (
      <li className="menu__list-item" key={index}>
        <a
          href={item.href}
          className="menu-social__link"
          rel="noreferrer"
          target="_blank"
        >
          {item.icon}
        </a>
      </li>
    ))}
  </ul>
);
