import React from "react";
import "./wallet-info.scss";
import { H4, ButtonText, H5Bold } from "../UI/Text";
import { ButtonBase, ButtonOutline } from "../UI/Button";

type WalletInfoProps = {
  img?: string;
  imgALt?: string;
  info: string;
  onClick: () => void;
  textButton: string;
  btnDisabled?: boolean;
};

export const WalletInfo: React.FC<WalletInfoProps> = ({
  img,
  imgALt,
  info,
  onClick,
  textButton,
  btnDisabled = false,
}) => (
  <div className="wallet-info">
    {img && imgALt && (
      <div className="wallet-info__top">
        <img className="wallet-info__img" src={img} alt={imgALt} />
      </div>
    )}
    <div className="wallet-info__body">
      <H4>{info}</H4>
    </div>

    <div className="wallet-info__bottom">
      <ButtonBase onClick={onClick} size="block" disabled={btnDisabled}>
        <ButtonText>{textButton}</ButtonText>
      </ButtonBase>
    </div>
  </div>
);

type WalletInfoLoginProps = {
  info: string;
  onClick: () => void;
  textButton: string;
  btnDisabled?: boolean;
};

export const WalletInfoLogin: React.FC<WalletInfoLoginProps> = ({
  info,
  onClick,
  textButton,
  btnDisabled = false,
}) => (
  <div className="wallet-info">
    <div className="wallet-info__body">
      <H5Bold>{info}</H5Bold>
    </div>

    <div className="wallet-info__bottom">
      <ButtonOutline onClick={onClick} size="block" disabled={btnDisabled}>
        <ButtonText>{textButton}</ButtonText>
      </ButtonOutline>
    </div>
  </div>
);
