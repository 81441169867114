import React from "react";
import "./icon-img.scss";
import cn from "classnames";

type IconImgProps = {
  icons?: string;
  name: string;
  size?: Size;
};

export type Size = "base" | "s" | "1s" | "2s" | "m";

export const IconImg: React.FC<IconImgProps> = ({
  icons,
  name,
  size = "base",
}) => (
  <svg className={cn("icon-img", name, size ? `icon-img--size-${size}` : "")}>
    <use xlinkHref={`${icons}#${name}`} />
  </svg>
);
