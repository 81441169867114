import { useWeb3React } from "@web3-react/core";
import { IBlockchain } from "../models/IBlockchain";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { changeNetwork } from "../utils/networkChanger";
import { useCallback, useEffect } from "react";
import { setModalState, setNeededBlockchain } from "../app/NetworkChangerSlice";

const useNetworkChanger = () => {
  const { chainId } = useWeb3React();
  const blockchains = useAppSelector((state) => state.config.blockchains);
  const { neededBlockchain } = useAppSelector((state) => state.networkChanger);
  const dispatch = useAppDispatch();
  const isCorrectChain = (needed?: number, current?: number) =>
    needed === current;

  const getChainById = (chainId: number): IBlockchain => {
    const blockchain = blockchains.find(
      (blockchain) => blockchain.chainId === chainId
    );
    if (blockchain) {
      return blockchain;
    }
    return {
      chainId: 0,
      name: "no_name",
      contractAddress: "0x0000000000000000000000000000000000000000",
      rpcUrl: "no_rpc",
      explorerUrl: "no_explorer",
      currencySymbol: "no_currency",
      verifiers: [],
    };
  };

  const closeModal = () => {
    dispatch(setModalState(false));
    dispatch(setNeededBlockchain(null));
  };

  useEffect(() => {
    if (neededBlockchain && neededBlockchain.chainId === chainId) {
      closeModal();
    }
  }, [chainId]);

  const changeChain = useCallback(
    async (needed: number, withModal = true) => {
      const neededChain = getChainById(needed);
      if (withModal) {
        dispatch(setModalState(true));
        dispatch(setNeededBlockchain(neededChain));
      }

      const data = await changeNetwork(neededChain);
      if (data === "CHANGED") {
        if (withModal) {
          closeModal();
        }
        return true;
      }

      if (data === "ERROR") {
        if (withModal) {
          closeModal();
        }
        return false;
      }
    },
    [chainId]
  );

  return {
    isCorrectChain: (need: number) => isCorrectChain(need, chainId),
    changeChain,
    getChainById,
  };
};

export default useNetworkChanger;
