import axios from "axios";

export const API_TYPE = process.env.REACT_APP_API_TYPE;

export const API_URL =
  API_TYPE === "PROD"
    ? "https://api.pass.hashbon.com"
    : "https://api.id.gefara.xyz";

const api = axios.create({
  baseURL: API_URL,
});

// todo: add interceptors

export default api;
