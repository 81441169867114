import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { WindowPassport } from "../../components/WindowPassport";
import { Container } from "../../components/Grid/Container/Container";
import { ButtonBase } from "../../components/UI/Button";
import { ButtonText, H2 } from "../../components/UI/Text";
import "./passports.scss";

import { Row } from "../../components/Grid/Row";
import { Column } from "../../components/Grid/Column/Column";

type PassportsProps = {
  onCreate?: () => void;
};

export const Passports: React.FC<PassportsProps> = ({ onCreate }) => {
  const navigate = useNavigate();
  const passport = useAppSelector((state) => state.user);

  return (
    <Container>
      <Row rowGap="xl" md={{ rowGap: "2xl" }} justify="center">
        <Column col={12}>
          <H2>Passports</H2>
        </Column>

        <Column col={12} md={{ col: 8 }} xl={{ col: 6 }}>
          <div className="passports__list">
            {passport.passports.map((passport) => (
              <WindowPassport
                key={`key-${passport.chainId}-${passport.id}`}
                passport={passport}
                onOpenClick={() => {
                  navigate(`/id/${passport.chainId}/${passport.id}`);
                }}
              />
            ))}

            <div className="passports__button-create">
              <ButtonBase onClick={onCreate} size="block">
                <ButtonText>Create Hashbon Pass</ButtonText>
              </ButtonBase>
            </div>
          </div>
        </Column>
      </Row>
    </Container>
  );
};
