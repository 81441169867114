import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBlockchain } from "../models/IBlockchain";

interface NetworkChangerState {
  isOpenModal: boolean;
  neededBlockchain: IBlockchain | null;
}

const initialState: NetworkChangerState = {
  isOpenModal: false,
  neededBlockchain: null,
};

export const networkChangerSlice = createSlice({
  name: "networkChanger",
  initialState,
  reducers: {
    setNeededBlockchain: (state, action: PayloadAction<IBlockchain | null>) => {
      state.neededBlockchain = action.payload;
    },
    setModalState: (state, action: PayloadAction<boolean>) => {
      state.isOpenModal = action.payload;
    },
  },
});

export const { setNeededBlockchain, setModalState } =
  networkChangerSlice.actions;
export default networkChangerSlice.reducer;
