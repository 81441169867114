import React, { useEffect, useState } from "react";
import snsWebSdk from "@sumsub/websdk";
import AuthService from "../../../../services/AuthService";
import "./sumsub-websdk-container.scss";
import { useAppSelector } from "../../../../app/hooks";
import { ModalMessage } from "../../../Modal/ModalMessage";
import { VerificationProviderProps } from "../../../../models/iVerifiers";

export const SumSubProvider: React.FC<VerificationProviderProps> = ({
  passportId,
  chainId,
}) => {
  const [isOpenModalVerificationDone, setIsOpenModalVerificationDone] =
    useState(false);
  const token = useAppSelector((state) => state.user.token);
  const [accessToken, setAccessToken] = useState<string>("");

  useEffect(() => {
    if (!token) return;
    AuthService.getVerificationInitData(passportId, chainId, token).then(
      (response) => {
        if (response.data.type === "success") {
          setAccessToken(response.data.data.accessToken);
        }
      }
    );
  }, [token]);

  useEffect(() => {
    if (!accessToken) return;
    (async () => {
      const snsWebSdkInstance = snsWebSdk
        .init(
          accessToken,
          () =>
            new Promise<string>((resolve) => {
              AuthService.getVerificationInitData(
                passportId,
                chainId,
                token
              ).then((response) => {
                if (response.data.type === "success") {
                  resolve(response.data.data.accessToken);
                }
              });
            })
        )
        .withOptions({
          addViewportTag: false,
          adaptIframeHeight: true,
        })
        .withConf({
          uiConf: {
            customCssStr:
              ":root {" +
              "--success-color:#00CBE3;" +
              "--primary-color: #ffffff;" +
              "--orange-color: #A253FA;" +
              "--red-color: #00CBE3;" +
              "--gray-color: #5d648a;" +
              "--success-color: #a664e3;" +
              "}" +
              "body {" +
              "font-family: Inter;" +
              "}" +
              "input, select, textarea {" +
              "color: #000000;" +
              "}" +
              "input, select {" +
              "height: 30px;" +
              "color: #A79CBA;" +
              "background: #221D2F;" +
              "border: 1px solid #5D648A;" +
              "box-sizing: border-box;" +
              "border-radius: 4px;" +
              "cursor: pointer;" +
              "}" +
              "input[placeholder] {" +
              "text-overflow: ellipsis;" +
              "}" +
              "input::-webkit-input-placeholder {" +
              "color: #a79cba;" +
              "}" +
              "input::-moz-placeholder  {" +
              "color: #a79cba;" +
              "text-overflow: ellipsis;" +
              "opacity: 1;" +
              "}" +
              "input::-ms-input-placeholder {" +
              "color: #a79cba;" +
              "text-overflow: ellipsis;" +
              "}" +
              "input:focus, input:hover {" +
              "border-color: var(--success-color);" +
              "}" +
              ".country-selector .value-flag {" +
              "top: 9px;" +
              "left: 6px;" +
              "}" +
              ".country-selector .show-hide {" +
              "color: #ffffff;" +
              "}" +
              ".country-selector .list {" +
              "margin-top: 4px;" +
              "color: #A79CBA;" +
              "background: #221D2F;" +
              "border: 1px solid #5D648A;" +
              "box-sizing: border-box;" +
              "border-radius: 4px;" +
              "scrollbar-width: thin;" +
              "scrollbar-color: #5c3482 #c78bff" +
              "}" +
              ".country-selector .list::-webkit-scrollbar {" +
              "width: 6px;" +
              "height: 80%;" +
              "}" +
              ".country-selector .list::-webkit-scrollbar-track {" +
              "background: #c78bff;" +
              "}" +
              ".country-selector .list::-webkit-scrollbar-thumb {" +
              "background: #5c3482;" +
              "border: 1px solid #5c3482;" +
              "}" +
              ".country-selector .list li.active, .country-selector .list li:hover {" +
              "background: #3F3557;" +
              "}" +
              "ul li{" +
              "color: #ffffff;" +
              "}" +
              ".step .title {" +
              "font-size: 14px;" +
              "color: #fff;" +
              "}" +
              ".step .bullet {" +
              "box-shadow: 0px 0px 16px rgba(199, 139, 255, 0.55);" +
              "background-color: #5c3482 !important;" +
              "}" +
              ".step.active .bullet {" +
              "background-color: #5c3482 !important;" +
              "}" +
              ".steps.mobile .step.active {" +
              "background-color: #5c3482;" +
              "border-color: #5c3482;" +
              "}" +
              "#loader .round-icon, .round-icon {" +
              "background: #A664E3;" +
              "box-shadow: 0px 0px 10px 1px rgba(166, 100, 227, 0.5);" +
              "}" +
              "button.submit {" +
              "font-family: Inter;" +
              "font-size: 14px;" +
              "line-height: 18px;" +
              "font-weight: 500;" +
              "border-radius: 3px;" +
              "border: 1px solid #a664e3;" +
              "background: #a664e3;" +
              "}" +
              "button.submit:hover:not(:disabled):not(.disabled):not(:active):hover {" +
              "border: 1px solid #bc74ff;" +
              "background: #bc74ff;" +
              "box-shadow: 0px 0px 50px 1px rgba(166, 100, 227, 0.5);" +
              "transform: translateY(0);" +
              "background-image: none;" +
              "}" +
              "button.submit:active:not(:disabled) {" +
              "background: #5C3482;" +
              "border: 1px solid #5C3482;" +
              "box-shadow: 0px 0px 50px 1px rgba(166, 100, 227, 0.5);" +
              "transform: translateY(0);" +
              "}" +
              "button.submit:disabled, button[type=submit]:disabled {" +
              "color: #ffffff;" +
              "background: #494949;" +
              "border: 1px solid #494949;" +
              "box-shadow: 0px 0px 50px 1px rgba(137, 125, 149, 0.5);" +
              "}" +
              ".mobile-button {" +
              "background: #5c3482;" +
              "box-shadow: 0px 0px 50px 1px rgba(166, 100, 227, 0.5);" +
              "}" +
              ".mobile-button:hover {" +
              "background-color: #bc74ff;" +
              "}" +
              ".sumsub-logo svg {" +
              "fill: #ffffff;" +
              "opacity: 1;" +
              "}" +
              "section.content {" +
              "color: #ffffff;" +
              "border-radius: 6px;" +
              "background-color: #292436;" +
              "border: 1px solid #5d648a;" +
              "}" +
              "#loader .message-icon {" +
              "background-color: green;" +
              "}" +
              "#loader .message-content {" +
              "color: green;" +
              "}" +
              "#loader, .loader-overlay {" +
              "background-color: transparent;" +
              "}" +
              // "#not-supported-msg {" +
              // "color: transparent;" +
              // "{" +
              "@media (max-width: 480px) {" +
              "body {" +
              "background: none;" +
              "}" +
              "}\n",
          },
        })
        // see below what kind of messages WebSDK generates
        .on("idCheck.stepCompleted", (payload) => {
          console.log("stepCompleted", payload);
          setIsOpenModalVerificationDone(true);
        })
        .on("idCheck.onError", (error) => {
          console.log("onError", error);
        })
        // .onTestEnv()
        .build();

      // you are ready to go:
      // just launch the WebSDK by providing the container element for it
      snsWebSdkInstance.launch("#sumsub-websdk-container");
    })();
  }, [accessToken]);

  const onCloseModalVerificationDone = () => {
    setIsOpenModalVerificationDone(false);
  };

  return (
    <>
      <ModalMessage
        title={"title"}
        text={"text"}
        isOpen={isOpenModalVerificationDone && false}
        onClose={onCloseModalVerificationDone}
      />
      <div id="sumsub-websdk-container" className="sumsub-websdk-container" />
    </>
  );
};
