import React from "react";
import "./connect-to-wallet-button.scss";
import cn from "classnames";
import { useWeb3React } from "@web3-react/core";
import useWeb3Auth from "../../app/useWeb3Auth";
import { BodyBoldSmall } from "../UI/Text";
import { shortenAddress } from "../../utils";
import { ConnectorNames } from "../../app/types";
import { useDispatch } from "react-redux";
import { setAddress, setPassports, setToken } from "../../app/UserSlice";

export const ConnectToWalletButton: React.FC = () => {
  const { active, account } = useWeb3React();
  const { login, logout } = useWeb3Auth();
  const dispatch = useDispatch();

  const connect = () => {
    if (active) {
      localStorage.removeItem("token");
      dispatch(setPassports([]));
      dispatch(setAddress(""));
      dispatch(setToken(""));
      return logout();
    } else {
      login(ConnectorNames.Injected);
    }
  };

  return (
    <button
      className="connect-to-wallet-button"
      type="button"
      onClick={connect}
    >
      <BodyBoldSmall tag="span">
        {account ? shortenAddress(account) : "Connect wallet"}
      </BodyBoldSmall>
      <span
        className={cn(
          "connect-to-wallet-button__state",
          account ? "connect-to-wallet-button__state--success" : ""
        )}
      />
    </button>
  );
};

export default ConnectToWalletButton;
