import React from "react";
import "./modal-change-nick.scss";
import { ButtonText } from "../../UI/Text";
import { ModalWrapper } from "../ModalWrapper";
import { ButtonBase } from "../../UI/Button";

type ModalChangeNickProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  buttonClick: () => void;
  children: React.ReactNode;
};

export const ModalChangeNick: React.FC<ModalChangeNickProps> = ({
  isOpen,
  onClose,
  buttonClick,
  title,
  children,
}) => (
  <ModalWrapper isOpen={isOpen} title={title} onClose={onClose}>
    <div className="modal-change-nick">
      {children}
      <ButtonBase onClick={buttonClick} size={"block"}>
        <ButtonText>Save</ButtonText>
      </ButtonBase>
    </div>
  </ModalWrapper>
);
