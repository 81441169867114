import React, { useState } from "react";
import "./input.scss";
import cn from "classnames";
import { BodySmall } from "../Text";

export type InputProps = {
  name: string;
  type: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  labelIcon?: React.ReactNode;
  error?: boolean;
  success?: boolean;
  maxLength?: number;
  notification?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Input: React.FC<InputProps> = ({
  name,
  type = "text",
  value = "",
  placeholder = "Input Text",
  disabled = false,
  label,
  labelIcon,
  error = false,
  success = false,
  maxLength,
  notification,
  onChange,
}) => {
  const [valueInput, setValueInput] = useState(value);

  return (
    <div
      className={cn(
        "input",
        error ? "input--error" : "",
        success ? "input--success" : ""
      )}
    >
      <label htmlFor={name} className="input__label">
        <BodySmall color="three">{label}</BodySmall>
        {labelIcon}
      </label>
      <input
        className="input__input"
        type={type}
        name={name}
        id={name}
        value={valueInput}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }

          setValueInput(e.target.value);
        }}
      />

      <div className="input__notification">
        {notification && <BodySmall>{notification}</BodySmall>}
      </div>
    </div>
  );
};
