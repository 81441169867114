import React from "react";
import "./column.scss";
import classNames from "classnames";

type ColumnProps = {
  children: React.ReactNode;
  col?: Col;
  order?: Order;
  alignSelf?: AlignSelf;
  flex?: boolean;
  md?: {
    col?: Col;
    order?: Order;
    alignSelf?: AlignSelf;
  };
  lg?: {
    col?: Col;
    order?: Order;
    alignSelf?: AlignSelf;
  };
  xl?: {
    col?: Col;
    order?: Order;
    alignSelf?: AlignSelf;
  };
  xxl?: {
    col?: Col;
    order?: Order;
    alignSelf?: AlignSelf;
  };
};

type Col = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto";
type AlignSelf =
  | "auto"
  | "normal"
  | "center"
  | "start"
  | "end"
  | "self-start"
  | "self-end"
  | "baseline"
  | "stretch"
  | "inherit"
  | "initial"
  | "unset";
type Order = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const Column: React.FC<ColumnProps> = ({
  children,
  col,
  alignSelf,
  order,
  md,
  lg,
  xl,
  xxl,
  flex = false,
}) => {
  return (
    <div
      className={`column ${classNames(
        col ? `column--col-${col}` : "",
        order ? `column--order-${order}` : "",
        flex ? "column--flex" : "",
        alignSelf ? `column--align-self-${alignSelf}` : "",
        md?.col ? `column--md-col-${md?.col}` : "",
        md?.order ? `column--md-order-${md?.order}` : "",
        md?.alignSelf ? `column--md-align-self-${md?.alignSelf}` : "",
        lg?.col ? `column--lg-col-${lg?.col}` : "",
        lg?.order ? `column--lg-order-${lg?.order}` : "",
        lg?.alignSelf ? `column--lg-align-self-${lg?.alignSelf}` : "",
        xl?.col ? `column--xl-col-${xl?.col}` : "",
        xl?.order ? `column--xl-order-${xl?.order}` : "",
        xl?.alignSelf ? `column--xl-align-self-${xl?.alignSelf}` : "",
        xxl?.col ? `column--xxl-col-${xxl?.col}` : "",
        xxl?.order ? `column--xxl-order-${xxl?.order}` : "",
        xxl?.alignSelf ? `column--xxl-align-self-${xxl?.alignSelf}` : ""
      )}`}
    >
      {children}
    </div>
  );
};
