import React from "react";
import "./modal-acept.scss";
import { BodyBoldLarge } from "../../UI/Text";

type ModalAceptProps = {
  desc: string;
  buttons: React.ReactNode;
};

export const ModalAcept: React.FC<ModalAceptProps> = ({ desc, buttons }) => (
  <div className="modal-acept">
    <BodyBoldLarge>{desc}</BodyBoldLarge>
    <div className="modal-acept__buttons">{buttons}</div>
  </div>
);
