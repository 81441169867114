import React from "react";
import {
  IconAurora,
  IconBsc,
  IconEthereum,
  IconOKXChain,
  IconPolygon,
} from "../UI/IconImg/IconImgs";

type ChainIconProps = {
  chainId: number;
};

export const ChainIcon: React.FC<ChainIconProps> = ({ chainId }) => {
  switch (chainId) {
    case 1:
      return <IconEthereum />;
    case 3:
      return <IconEthereum />;
    case 4:
      return <IconEthereum />;
    case 56:
      return <IconBsc />;
    case 97:
      return <IconBsc />;
    case 137:
      return <IconPolygon />;
    case 1313161554:
      return <IconAurora />;
    case 66:
      return <IconOKXChain />;
  }
  return <></>;
};
