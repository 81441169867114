import React from "react";
import cn from "classnames";
import "./button.scss";

export const ButtonLinkBase: React.FC<ButtonProps> = ({
  children,
  ...props
}) => (
  <Button {...props} type="link">
    {children}
  </Button>
);

export const ButtonLinkOutline: React.FC<ButtonProps> = ({
  children,
  ...props
}) => (
  <Button {...props} type="link" variant="outline">
    {children}
  </Button>
);

export const ButtonLinkFlat: React.FC<ButtonProps> = ({
  children,
  ...props
}) => (
  <Button {...props} type="link" variant="flat">
    {children}
  </Button>
);

export const ButtonLinkIcon: React.FC<ButtonProps> = ({
  children,
  ...props
}) => (
  <Button {...props} type="link" variant="icon">
    {children}
  </Button>
);

export const ButtonBase: React.FC<ButtonProps> = ({ children, ...props }) => (
  <Button {...props}>{children}</Button>
);

export const ButtonSecondary: React.FC<ButtonProps> = ({
  children,
  ...props
}) => (
  <Button variant="secondary" {...props}>
    {children}
  </Button>
);

export const ButtonOutline: React.FC<ButtonProps> = ({
  children,
  ...props
}) => (
  <Button variant="outline" {...props}>
    {children}
  </Button>
);

export const ButtonFlat: React.FC<ButtonProps> = ({ children, ...props }) => (
  <Button variant="flat" {...props}>
    {children}
  </Button>
);

export const ButtonIcon: React.FC<ButtonProps> = ({ children, ...props }) => (
  <Button variant="icon" {...props}>
    {children}
  </Button>
);

export const ButtonBtnIcon: React.FC<ButtonProps> = ({
  children,
  ...props
}) => (
  <Button variant="btn-icon" {...props}>
    {children}
  </Button>
);

type ButtonProps = {
  children?: React.ReactNode;
  variant?: Variant;
  size?: SizeBtn;
  type?: "button" | "submit" | "reset" | "link";
  disabled?: boolean;
  onClick?: () => void;
  url?: string;
  target?: "_blank";
};
type SizeBtn = "block";
type Variant = "outline" | "icon" | "flat" | "secondary" | "btn-icon";

const Button: React.FC<ButtonProps> = ({
  children,
  size,
  onClick,
  variant,
  type = "button",
  url,
  target,
  disabled = false,
  ...props
}) => (
  <>
    {type === "button" && (
      <button
        className={cn(
          "btn",
          size ? `btn--${size}` : "",
          variant ? `btn--${variant}` : ""
        )}
        onClick={onClick}
        type={type}
        disabled={disabled}
        {...props}
      >
        {children}
      </button>
    )}

    {type === "link" && (
      <a
        href={url}
        className={cn(
          "btn",
          size ? `btn--${size}` : "",
          variant ? `btn--${variant}` : ""
        )}
        role="button"
        target={target}
        {...props}
      >
        {children}
      </a>
    )}
  </>
);
