import React from "react";
import { useWeb3React } from "@web3-react/core";
// import { ModalMessage } from "../ModalMessage";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setModalState } from "../../../app/NetworkChangerSlice";
import { ButtonOutline } from "../../UI/Button";
import { BodySmall, ButtonText } from "../../UI/Text";
import { ModalWrapper } from "../ModalWrapper";

export const ModalNetwork: React.FC = () => {
  const { chainId } = useWeb3React();
  const dispatch = useAppDispatch();
  const { isOpenModal, neededBlockchain } = useAppSelector(
    (state) => state.networkChanger
  );
  const isOpen = (isOpenModal &&
    neededBlockchain &&
    chainId !== neededBlockchain.chainId) as boolean;
  const onClose = () => {
    dispatch(setModalState(false));
  };
  return (
    // <ModalMessage
    //   isOpen={isOpen}
    //   onClose={onClose}
    //   title={`Please switch chain`}
    //   text={`Please switch to ${neededBlockchain?.name} chain before proceeding to the next step`}
    // />
    <ModalWrapper isOpen={isOpen} title="Please switch chain">
      <div className="modal-error">
        <BodySmall>
          Please switch to {neededBlockchain?.name} chain before proceeding to
          the next step.
        </BodySmall>
        <ButtonOutline onClick={onClose} size={"block"}>
          <ButtonText>Ok</ButtonText>
        </ButtonOutline>
      </div>
    </ModalWrapper>
  );
};
